import CRC32 from 'crc-32';
import axios from 'axios';
import moment from 'moment';
import omit from 'lodash/omit';
import queryString from 'query-string';
import { pos } from '@Utils/preference-keys';
import { getDiscountDescription, getProductDescription, getVunitIdForReceipt } from '@Utils/pos-utils';
import { getDefaultVatPct } from '@State/selectors';
import {
  getSaleItems, getSaleId, getSalePaidAmount, getSaleRemainingAmount,
  getSaleChecksum, getAutoLoginOperator, getSaleCustomer, getSalePrePayments,
  getPosUnitPrefs, getOperatorId, getReconcileRequired
} from '@State/pos-selectors';
import {
  axiosDefault, axiosErrorHandler, checkStatusAxios, prefixUrl
} from '@Utils/ajax-util';
import { navigate } from '@Utils/navigate';
import { pauseScanner, resumeScanner } from '@Utils/barcode';
import { pauseOperatorTimer, resumeOperatorTimer } from '@Components/pos/operator-auto-logout';
import { getSessionPosUnit, setSessionPosUnit, setSessionOpToken } from '@Utils/session';
import { POS_TERMINALS_BY_UNIT_FETCHED, POS_TERMINAL_UPDATED, POS_TERMINAL_BUSY, POS_TERMINAL_READY, fetchPrinters, switchPosUnitDevice } from './pos-config-actions';
import { fetchSalePrePayments, POS_PRE_PAYMENTS_FETCHED } from './booking-actions';
import { setCustomerInvoiceCustomer } from './customer-actions';
import { PRODUCT_SOLD } from './products-actions';

export const POS_QUEUE_BOOKING = 'POS_QUEUE_BOOKING';
export const POS_ADD_SALE_ITEM = 'POS_ADD_SALE_ITEM';
export const POS_REMOVE_SALE_ITEM = 'POS_REMOVE_SALE_ITEM';
export const POS_UPDATE_SALE_ITEM = 'POS_UPDATE_SALE_ITEM';
export const POS_SALE_BOOKING_ADDED = 'POS_SALE_BOOKING_ADDED';
export const POS_RESET_QUEUED_BOOKING = 'POS_RESET_QUEUED_BOOKING';
export const POS_SALE_CUSTOMER_ADDED = 'POS_SALE_CUSTOMER_ADDED';
export const POS_SALE_CUSTOMER_REMOVED = 'POS_SALE_CUSTOMER_REMOVED';
export const POS_SET_OPERATOR = 'POS_SET_OPERATOR';
export const POS_SALES_FETCHED = 'POS_SALES_FETCHED';
export const POS_SALE_CREATED = 'POS_SALE_CREATED';
export const POS_SALE_PARKED = 'POS_SALE_PARKED';
export const POS_SALE_UNPARKED = 'POS_SALE_UNPARKED';
export const POS_SALE_VOIDED = 'POS_SALE_VOIDED';
export const POS_SALE_COMPLETED = 'POS_SALE_COMPLETED';
export const POS_SALE_FETCHED = 'POS_SALE_FETCHED';
export const POS_SET_POS_UNIT = 'POS_SET_POS_UNIT';
export const POS_UNITS_FETCHED = 'POS_UNITS_FETCHED';
export const POS_PAYMENT_MODAL = 'POS_PAYMENT_MODAL';
export const POS_PAYMENT_RESULT = 'POS_PAYMENT_RESULT';
export const POS_PAYMENT_PROGRESS = 'POS_PAYMENT_PROGRESS';
export const POS_PAYMENT_PROGRESS_RESET = 'POS_PAYMENT_PROGRESS_RESET';
export const POS_PAYMENT_CHECKSUM_MISMATCH = 'POS_PAYMENT_CHECKSUM_MISMATCH';
export const POS_RECEIPT_FETCHED = 'POS_RECEIPT_FETCHED';
export const POS_RECEIPTS_FETCHED = 'POS_RECEIPTS_FETCHED';
export const POS_RECEIPT_COPY_PRINTED = 'POS_RECEIPT_COPY_PRINTED';
export const POS_RECEIPT_COPY_RESET = 'POS_RECEIPT_COPY_RESET';
export const POS_REPORT_FETCHED = 'POS_REPORT_FETCHED';
export const POS_REPORTS_FETCHED = 'POS_REPORTS_FETCHED';
export const POS_UPDATE_SALE_ITEM_LOCAL_ID = 'POS_UPDATE_SALE_ITEM_LOCAL_ID';
export const POS_REFUND_COMPLETED = 'POS_REFUND_COMPLETED';
export const POS_TRANSACTIONS_FETCHED = 'POS_TRANSACTIONS_FETCHED';
export const POS_TRANSACTIONS_ADDED = 'POS_TRANSACTIONS_ADDED';
export const POS_RETURN_URL = 'POS_RETURN_URL';

// Named receiptId in pusher event but saleReceiptId in sales model
export const getSaleReceiptId = (state) => state.posSale.get('receiptId') || state.posSale.get('saleReceiptId');
export const getRefundReceiptId = (state) => state.posSale.get('receiptId') || state.posSale.get('refundReceiptId');

const getPosUrl = (prefix, path, vunitId) => prefixUrl(`/pos/${prefix}/vunit/${vunitId || getSessionPosUnit()}${path}`);
const getVunitUrl = (path, vunitId) => prefixUrl(`/pos/vunits/${vunitId || getSessionPosUnit()}${path}`);
const getSalesUrl = (path, vunitId) => getPosUrl('sales', path, vunitId);
const getReceiptsUrl = (path, vunitId) => getPosUrl('receipts', path, vunitId);
const getReportsUrl = (path, vunitId) => getPosUrl('reports', path, vunitId);

export function setPosUnit(posUnit) {
  return dispatch => {
    dispatch({ type: POS_SET_POS_UNIT, posUnit });
    setSessionPosUnit(posUnit && posUnit.vunitId);

    if (posUnit?.vunitId) {
      dispatch(loginVunit(posUnit.posOrgId, posUnit.vunitId));

      if (posUnit.state === 'OpenNoCurrentDevice') {
        dispatch(switchPosUnitDevice(posUnit.vunitId));
      }
    }
  };
}

function setPosUnitFromSession(posUnits) {
  return dispatch => {
    const posUnitId = getSessionPosUnit();
    if (posUnitId) {
      const posUnit = posUnits.find(u => u.vunitId == posUnitId);
      dispatch({ type: POS_SET_POS_UNIT, posUnit });
    }
  };
}

export function setOperator(operator) {
  return dispatch => {
    dispatch({ type: POS_SET_OPERATOR, operator });
  };
}

export function loginOperator({ pin, userId }) {
  return (dispatch) => {
    const url = prefixUrl('/operator/auth');
    const config = axiosDefault();

    return axios
      .post(url, { pin, userId }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ opId, opName, opToken, vunits }) => {
        setSessionOpToken(opToken);
        dispatch(setOperator({ opId, opName, opToken }));
        dispatch({ type: POS_UNITS_FETCHED, units: vunits });
      });
  };
}

export function logoutOperator() {
  return (dispatch, getState) => {
    const { pos } = getState();
    const posUnit = pos.get('posUnit');
    if (posUnit) {
      dispatch(logoutVunit(posUnit.get('posOrgId'), posUnit.get('vunitId')));
    }

    setSessionOpToken(null);
    dispatch(setOperator(null));
    dispatch(setPosUnit(null));
  };
}

export function fetchAvailablePosUnits() {
  return (dispatch, getState) => {
    const config = axiosDefault();
    const { pos } = getState();
    const url = prefixUrl('/pos/vunits/available/');

    if (!pos.get('operator')) {
      return;
    }

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ vunits }) => {
        dispatch(setPosUnitFromSession(vunits));
        dispatch({ type: POS_UNITS_FETCHED, units: vunits });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchState() {
  return (dispatch, getState) => {
    const url = getVunitUrl('/state/');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => {
        const { openSales, terminals, transactions, prePayments } = res.data;
        dispatch({ type: POS_SALES_FETCHED, sales: openSales });
        dispatch({ type: POS_TERMINALS_BY_UNIT_FETCHED, terminals });
        dispatch({ type: POS_TRANSACTIONS_FETCHED, transactions });
        dispatch({ type: POS_PRE_PAYMENTS_FETCHED, prePayments });
      })
      .then(() => {
        const saleId = getSaleId(getState());
        if (!saleId) {
          dispatch(addQueuedBookingPayment());
        }
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchSale(saleId, vunitId) {
  return (dispatch) => {
    const url = getSalesUrl(`/sales/${saleId}`, vunitId);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch({ type: POS_SALE_FETCHED, sale: res.data }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchSaleForRefund(saleId, vunitId) {
  return (dispatch) => {
    const url = getSalesUrl(`/sales/${saleId}/refundable-sale`, vunitId);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch({ type: POS_SALE_FETCHED, sale: res.data }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function createSale({ item, bookingId, customer, multiVat }) {
  return (dispatch, getState) => {
    const url = getSalesUrl('/sales/');
    const config = axiosDefault();
    const { customerId } = customer || {};

    return axios.post(url, { item, bookingId, customerId }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then((res) => {
        const { saleId, items } = res.data;
        dispatch({
          type: POS_SALE_CREATED, saleId, items: bookingId ? items : null, customer
        });

        if (item && items && multiVat) {
          dispatch(replaceLocalSaleItemWithItems(item, items));
        } else if (item && items && items.length === 1) {
          dispatch(setIdForLocalSaleItem(item, items[0].id));
        }
      })
      .catch(error => {
        dispatch({ type: POS_SALE_VOIDED });
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function parkSale() {
  return (dispatch, getState) => {
    const state = getState();
    const { posSale } = state;
    const saleId = posSale.get('id');
    const url = getSalesUrl(`/sales/${saleId}/park`);
    const config = axiosDefault();

    const createdTs = moment().toISOString();
    const paidAmount = getSalePaidAmount(state);
    const remainingAmount = getSaleRemainingAmount(state);
    const sale = posSale.merge({ createdTs, paidAmount, remainingAmount });

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch({ type: POS_SALE_PARKED, sale }))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function unparkSale(sale) {
  return (dispatch) => {
    const url = getSalesUrl(`/sales/${sale.get('id')}/unpark`);
    const config = axiosDefault();

    const status = sale.get('paidAmount') > 0 ? 'PartialPaid' : 'Unpaid';
    const newSale = sale.set('status', status);

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch({ type: POS_SALE_UNPARKED, sale: newSale }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function voidSale() {
  return (dispatch, getState) => {
    const saleId = getSaleId(getState());
    const url = getSalesUrl(`/sales/${saleId}/void`);
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch({ type: POS_SALE_VOIDED, saleId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function queueBookingPayment(booking) {
  return {
    type: POS_QUEUE_BOOKING,
    booking
  };
}

export function addQueuedBookingPayment() {
  return (dispatch, getState) => {
    const { pos } = getState();
    const booking = pos.get('queuedBooking');
    if (booking) {
      const { returnUrl, addServiceId } = booking.attributes || {};
      if (returnUrl) {
        dispatch({ type: POS_RETURN_URL, returnUrl });
      }
      return dispatch(addBookingToSale(booking))
        .then(() => dispatch(fetchSalePrePayments()))
        .then(() => {
          if (addServiceId) {
            dispatch(addServiceToSale(addServiceId));
          }
        });
    }
    return Promise.resolve();
  };
}

function addServiceToSale(addServiceId) {
  return (dispatch, getState) => {
    const { servicesById, posSale } = getState();
    const service = servicesById.get(addServiceId);
    const saleItems = posSale.get('items');

    if (service) {
      saleItems.forEach(item => {
        dispatch(removeSaleItem(item.get('id')));
      });
      dispatch(addService(service));
    }
  };
}

export function resetQueuedBookingPayment() {
  return {
    type: POS_RESET_QUEUED_BOOKING
  };
}

function addBookingToSale(booking) {
  return (dispatch, getState) => {
    const state = getState();
    const saleId = getSaleId(state);
    const url = getSalesUrl(`/sales/${saleId}/booking/`);
    const config = axiosDefault();

    const { bookingId, customer } = booking;
    const saleCustomer = getSaleCustomer(state);
    const addCustomer = !saleCustomer ? customer : null;

    if (!saleId) {
      return dispatch(createSale({ bookingId, customer: addCustomer }));
    }

    return axios.post(url, { bookingId, customer }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => {
        const { items } = res.data;
        dispatch({ type: POS_SALE_BOOKING_ADDED, bookingId, items, customer: addCustomer });
      })
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function addCustomerToSale(customer) {
  return (dispatch, getState) => {
    const saleId = getSaleId(getState());
    const url = getSalesUrl(`/sales/${saleId}/customer/${customer.customerId}`);
    const config = axiosDefault();

    if (!saleId) {
      return dispatch(createSale({ customer }));
    }

    return axios.put(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch({ type: POS_SALE_CUSTOMER_ADDED, customer }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function removeCustomerFromSale() {
  return (dispatch, getState) => {
    const saleId = getSaleId(getState());
    const url = getSalesUrl(`/sales/${saleId}/customer/`);
    const config = axiosDefault();

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch({ type: POS_SALE_CUSTOMER_REMOVED }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

function replaceLocalSaleItemWithItems(newItem, items) {
  return (dispatch) => {
    const { showEditModal } = newItem;
    dispatch({ type: POS_REMOVE_SALE_ITEM, idLocal: newItem.idLocal });
    items.forEach(item => {
      dispatch({ type: POS_ADD_SALE_ITEM, item: { ...item, showEditModal } });
    });
  };
}

function setIdForLocalSaleItem(newItem, itemId) {
  return (dispatch) => {
    const item = { ...omit(newItem, 'idLocal'), id: itemId };
    dispatch({ type: POS_UPDATE_SALE_ITEM, item });
  };
}

function addSaleItem(item, multiVat) {
  return (dispatch, getState) => {
    const saleId = getSaleId(getState());
    const url = multiVat
      ? getSalesUrl(`/sales/${saleId}/items/multivat/`)
      : getSalesUrl(`/sales/${saleId}/items/`);
    const config = axiosDefault();
    const newItem = {
      ...item,
      showEditModal: item.itemType !== 'Discount' && item.amount === 0,
      saleIdLocal: `saleIdLocal${Math.random().toString(16).slice(2)}`,
      idLocal: `id${Math.random().toString(16).slice(2)}`
    };

    dispatch({ type: POS_ADD_SALE_ITEM, item: newItem });

    if (!saleId) {
      dispatch({ type: POS_SALE_CREATED, saleId: newItem.saleIdLocal });
      return dispatch(createSale({ item: newItem, multiVat }));
    }

    // fake sale id, don`t do nothing
    if (typeof saleId === 'string' && saleId.includes('saleId')) {
      return Promise.resolve();
    }

    return axios.post(url, item, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => {
        if (multiVat) {
          dispatch(replaceLocalSaleItemWithItems(newItem, res.data.items));
        } else {
          dispatch(setIdForLocalSaleItem(newItem, res.data.itemId));
        }
      })
      .catch(error => {
        dispatch({ type: POS_REMOVE_SALE_ITEM, idLocal: newItem.idLocal });
        axiosErrorHandler(error, dispatch);
      });
  };
}

function handleExistingSaleItem(existing, quantity) {
  return (dispatch) => {
    const itemOld = existing.toJS();
    const item = { ...itemOld, quantity: itemOld.quantity + 1 };

    if (existing.get('id')) {
      return dispatch(updateSaleItem(item));
    }
    if (existing.get('idLocal')) {
      dispatch({ type: POS_UPDATE_SALE_ITEM_LOCAL_ID, item });
      return Promise.resolve();
    }
  };
}

export function addProduct(product) {
  return (dispatch, getState) => {
    const state = getState();
    const sellerId = getOperatorId(state);
    const posUnitPrefs = getPosUnitPrefs(state);
    const existing = getSaleItems(state).find(i => i.get('articleId') === product.id);

    if (existing && posUnitPrefs[pos.mergeSameArticlesOnReceipt]) {
      return dispatch(handleExistingSaleItem(existing));
    }

    return dispatch(addSaleItem({
      itemType: 'Product',
      articleId: product.id,
      description: getProductDescription(product, posUnitPrefs),
      vatPct: product.vatPct,
      amount: product.priceOut || 0,
      quantity: product.quantity || 1,
      sellerId
    }));
  };
}

export function addService(service) {
  return (dispatch, getState) => {
    const state = getState();
    const sellerId = getOperatorId(state);
    const posUnitPrefs = getPosUnitPrefs(state);
    const defaultVatPct = getDefaultVatPct(state);
    const existing = getSaleItems(state).find(i => i.get('serviceId') === service.id);

    if (existing && !service.multiVat && posUnitPrefs[pos.mergeSameArticlesOnReceipt]) {
      return dispatch(handleExistingSaleItem(existing));
    }

    return dispatch(addSaleItem(mapService(service, defaultVatPct, sellerId), service.multiVat));
  };
}

export function mapService(service, defaultVatPct, sellerId) {
  return {
    itemType: 'Service',
    serviceId: service.id,
    description: service.name,
    amount: service.price || 0,
    vatPct: !isNaN(service.vatPct) ? service.vatPct : defaultVatPct,
    quantity: 1,
    sellerId
  };
}

export function addDiscount(discount) {
  return (dispatch, getState) => {
    const sellerId = getOperatorId(getState());
    const { discountValue, discountType, comment } = discount;
    const description = getDiscountDescription(discountValue, discountType);

    return dispatch(addSaleItem({
      itemType: 'Discount',
      description,
      discountType,
      discountValue,
      quantity: 1,
      amount: 0,
      vatPct: 0,
      comment,
      sellerId
    }));
  };
}

export function addGiftCard(giftCard) {
  return (dispatch, getState) => {
    const sellerId = getOperatorId(getState());
    const {
      id, code, description, comment, beneficiary, initialValue, vatPct
    } = giftCard;

    return dispatch(addSaleItem({
      voucherId: id,
      itemType: 'VoucherSale',
      description,
      comment,
      voucherCode: code,
      voucherBeneficiary: beneficiary,
      quantity: 1,
      amount: initialValue,
      vatPct,
      sellerId
    }));
  };
}

let timer;
export function updateSaleItem(item) {
  return (dispatch, getState) => {
    const saleId = getSaleId(getState());
    const url = getSalesUrl(`/sales/${saleId}/items/${item.id}`);
    const config = axiosDefault();

    dispatch({ type: POS_UPDATE_SALE_ITEM, item });

    if (timer) {
      clearTimeout(timer);
    }

    return new Promise((resolve) => {
      timer = setTimeout(() => {
        return axios.put(url, item, config)
          .then(res => dispatch(checkStatusAxios(res)))
          .then(resolve)
          .catch(error => {
            dispatch(fetchSale(saleId));
            axiosErrorHandler(error, dispatch);
          });
      }, 200);
    });
  };
}

export function removeSaleItem(itemId) {
  return (dispatch, getState) => {
    const saleId = getSaleId(getState());
    const url = getSalesUrl(`/sales/${saleId}/items/${itemId}`);
    const config = axiosDefault();

    dispatch({ type: POS_REMOVE_SALE_ITEM, itemId });

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => {
        dispatch(fetchSale(saleId));
        axiosErrorHandler(error, dispatch);
      });
  };
}

export function showPaymentModal(modal) {
  return (dispatch) => {
    if (modal && modal.indexOf('Payment') !== -1) {
      dispatch(validateChecksum());
      dispatch(fetchPrinters());
    }
    dispatch({ type: POS_PAYMENT_MODAL, modal });
    pauseOperatorTimer();
    pauseScanner();
  };
}

export function hidePaymentModal() {
  return (dispatch) => {
    dispatch({ type: POS_PAYMENT_MODAL, modal: null });
    resumeOperatorTimer();
    resumeScanner();
  };
}

export function resetChecksumMismatch() {
  return { type: POS_PAYMENT_CHECKSUM_MISMATCH, error: null };
}

function paymentErrorHandler(error, payment, checksumStr, checksum) {
  return (dispatch) => {
    if (error.response?.status === 400) {
      if (error.response.data?.code === 3001) {
        // Checksum error, show info modal and refesh state
        dispatch({ type: POS_PAYMENT_CHECKSUM_MISMATCH, error });
        Sentry.captureMessage(`Checksum error: ${checksumStr}, ${checksum}`);
        return;
      }
    }
    if (!['Card', 'Invoice', 'FortnoxInvoice'].includes(payment.paymentMethod)) {
      axiosErrorHandler(error, dispatch);
    }
  };
}

export function validateChecksum() {
  return (dispatch, getState) => {
    const state = getState();
    const saleId = getSaleId(state);
    const checksumStr = getSaleChecksum(state);
    const checksum = CRC32.str(checksumStr);

    const url = getSalesUrl(`/sales/${saleId}/validate-checksum`);
    const config = axiosDefault();

    return axios.post(url, { checksum }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => {
        dispatch(paymentErrorHandler(error, null, checksumStr, checksum));
      });
  };
}

export function addPayment(payment) {
  return (dispatch, getState) => {
    const state = getState();
    const saleId = getSaleId(state);
    const checksumStr = getSaleChecksum(state);
    const prePayments = getSalePrePayments(state);
    const checksum = CRC32.str(checksumStr);

    const url = getSalesUrl(`/sales/${saleId}/payment`);
    const config = axiosDefault();

    dispatch(resetPaymentProgress());
    return axios.post(url, { ...payment, checksum }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => {
        const { transactionStatus, paymentMethod, terminalId } = res.data;
        if (transactionStatus === 'InProgress' && paymentMethod === 'Card') {
          dispatch({ type: POS_TERMINAL_BUSY, terminalId });
        }
        dispatch({ type: POS_PAYMENT_RESULT, saleId, ...res.data });

        if (prePayments) {
          dispatch({ type: POS_TRANSACTIONS_ADDED, saleId, transactions: prePayments });
        }
      })
      .catch(error => {
        dispatch(paymentErrorHandler(error, payment, checksumStr, checksum));
        throw error;
      });
  };
}

export function createInvoice(invoice) {
  return (dispatch, getState) => {
    const state = getState();
    const saleId = getSaleId(state);
    const checksumStr = getSaleChecksum(state);
    const checksum = CRC32.str(checksumStr);

    const url = getSalesUrl(`/sales/${saleId}/invoice`);
    const config = axiosDefault();

    return axios.post(url, { ...invoice, checksum }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => {
        dispatch({ type: POS_PAYMENT_RESULT, saleId, ...res.data });

        const { customerId, invoiceCustomerId } = invoice;
        if (customerId) {
          dispatch(setCustomerInvoiceCustomer(customerId, invoiceCustomerId));
        }
      })
      .catch(error => {
        dispatch(paymentErrorHandler(error, invoice, checksumStr, checksum));
        throw error;
      });
  };
}

export function resetPaymentProgress() {
  return {
    type: POS_PAYMENT_PROGRESS_RESET
  };
}

export function paymentProgress(posUnitId, data) {
  return (dispatch, getState) => {
    const state = getState();
    const posUnit = state.pos.get('posUnit');
    const ignoreTerminalReady = getReconcileRequired(state);

    if (posUnit && posUnit.get('vunitId') === posUnitId) {
      if (data.terminalReady && !ignoreTerminalReady) {
        dispatch({
          type: POS_TERMINAL_READY,
          terminalId: data.terminalId
        });
      } else {
        dispatch({
          type: POS_PAYMENT_PROGRESS,
          status: data.confirmPinByPass ? 'AwaitingConfirm' : null,
          message: data.message
        });
      }
    }
  };
}

function shouldIgnorePaymentResult(paymentResult, posSale) {
  const { saleId, paymentMethod, transactionStatus, rejectionReason } = paymentResult;
  const isSwish = paymentMethod === 'SwishMerchant';
  const isKlarna = paymentMethod === 'KlarnaPayments';
  const isRejected = transactionStatus === 'Rejected';
  const isSucceeded = transactionStatus === 'Succeeded';
  const saleCompleted = posSale.get('status') === 'Paid';

  // Prevent race condition between Pusher and polling
  if (saleId === posSale.get('id') && isSucceeded && saleCompleted) {
    return true;
  }
  // Klarna cancelled from POS, ignore Pusher message
  if (isKlarna && isRejected && rejectionReason === 'DISABLED') {
    return true;
  }
  // Swish cancelled from POS, ignore Pusher message
  if (isSwish && isRejected && rejectionReason === 'CANCELLED') {
    return true;
  }
  return false;
}

export function paymentResult(posUnitId, data) {
  return (dispatch, getState) => {
    const { pos, posSale } = getState();
    const posUnit = pos.get('posUnit');

    if (data && shouldIgnorePaymentResult(data, posSale)) {
      return;
    }

    if (posUnit && posUnit.get('vunitId') === posUnitId) {
      dispatch({ type: POS_PAYMENT_RESULT, ...data });
    }
  };
}

export function confirmPinBypass(saleId, allow) {
  return (dispatch) => {
    const url = getSalesUrl(`/sales/${saleId}/confirm-pin-bypass?allow=${allow}`);
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function confirmSignature(saleId, accept) {
  return (dispatch) => {
    const url = getSalesUrl(`/sales/${saleId}/tx-reversal/ref`);
    const config = axiosDefault();

    if (accept) {
      dispatch({ type: POS_PAYMENT_PROGRESS, status: 'Completed', message: null });
      return Promise.resolve();
    }

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function cancelPayment(saleId) {
  return (dispatch) => {
    const url = getSalesUrl(`/sales/${saleId}/tx-cancel`);
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchPaymentStatus(saleId, terminalId) {
  return (dispatch) => {
    const query = terminalId ? `?terminalId=${terminalId}` : '';
    const url = getSalesUrl(`/sales/${saleId}/tx-status${query}`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(({ data }) => {
        const { transactionResult, terminalStatus } = data;
        if (terminalId && terminalStatus) {
          dispatch({
            type: POS_PAYMENT_PROGRESS,
            status: terminalStatus.deviceStatus,
            message: terminalStatus.displayText
          });
          dispatch({
            type: POS_TERMINAL_UPDATED,
            terminal: { id: transactionResult.terminalId, terminalStatus }
          });
        }
        const paymentResult = transactionResult || { transactionStatus: 'Rejected' };
        dispatch({ type: POS_PAYMENT_RESULT, saleId, ...paymentResult });
      });
  };
}

export function printReceiptCopy(receiptId) {
  return (dispatch, getState) => {
    const { posReceipts } = getState();
    const vunitId = getVunitIdForReceipt(posReceipts, receiptId);
    const url = getReceiptsUrl(`/receipts/${receiptId}/print-copy`, vunitId);
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(() => dispatch({ type: POS_RECEIPT_COPY_PRINTED, receiptId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function sendReceiptCopy(receiptId, email) {
  return (dispatch, getState) => {
    const { posReceipts } = getState();
    const vunitId = getVunitIdForReceipt(posReceipts, receiptId);
    const url = getReceiptsUrl(`/receipts/${receiptId}/email-copy`, vunitId);
    const config = axiosDefault();

    return axios.post(url, { email }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: POS_RECEIPT_COPY_PRINTED, receiptId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

function deliverReceipt(receiptId, receiptData) {
  return (dispatch) => {
    const url = getReceiptsUrl(`/receipts/${receiptId}/deliver`);
    const config = axiosDefault();

    return axios.post(url, receiptData, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

function updateProductsSold(items) {
  return (dispatch) => {
    items.forEach((item) => {
      if (item.get('itemType') === 'Product') {
        dispatch({
          type: PRODUCT_SOLD,
          id: item.get('articleId'),
          quantity: item.get('quantity')
        });
      }
    });
  };
}

function saleCompleted() {
  return (dispatch, getState) => {
    const state = getState();
    const saleId = getSaleId(state);
    const autoLoginOperator = getAutoLoginOperator(state);
    const logoutAfterPurchase = state.locationConfig.get(pos.logoutAfterPurchase);

    dispatch(updateProductsSold(state.posSale.get('items')));
    dispatch({ type: POS_SALE_COMPLETED, saleId });

    if (state.pos.get('returnUrl')) {
      setTimeout(() => {
        dispatch({ type: POS_RETURN_URL, returnUrl: null });
        navigate(state.pos.get('returnUrl'));
      }, 200);
    }
    if (!autoLoginOperator && logoutAfterPurchase) {
      setTimeout(() => dispatch(logoutOperator()), 500);
    }
  };
}

function refundCompleted() {
  return (dispatch, getState) => {
    const state = getState();
    const saleId = getSaleId(state);

    dispatch({ type: POS_REFUND_COMPLETED, saleId });
  };
}

export function completeSale(receiptData) {
  return (dispatch, getState) => {
    const state = getState();
    const receiptId = getSaleReceiptId(state);

    if (!receiptData) {
      dispatch(saleCompleted());
      return Promise.resolve();
    }

    return dispatch(deliverReceipt(receiptId, receiptData))
      .then(() => dispatch(saleCompleted()));
  };
}

export function completeRefund(receiptData) {
  return (dispatch, getState) => {
    const state = getState();
    const receiptId = getSaleReceiptId(state);

    if (!receiptData) {
      dispatch(refundCompleted());
      return Promise.resolve();
    }

    return dispatch(deliverReceipt(receiptId, receiptData))
      .then(() => dispatch(refundCompleted()));
  };
}

export function fetchReceipts(startDate, endDate) {
  return dispatch => {
    const query = queryString.stringify({
      sd: startDate.format('YYYY-MM-DD'),
      ed: endDate.format('YYYY-MM-DD')
    });
    const url = `${getReceiptsUrl('/receipts/')}?${query}`;
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(({ data }) => dispatch({ type: POS_RECEIPTS_FETCHED, receipts: data.receipts }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchReceipt(receiptId) {
  return (dispatch, getState) => {
    const config = axiosDefault();
    const { posReceipts } = getState();
    const vunitId = getVunitIdForReceipt(posReceipts, receiptId);
    const url = getReceiptsUrl(`/receipts/${receiptId}`, vunitId);

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(({ data }) => dispatch({ type: POS_RECEIPT_FETCHED, receipt: { receiptId, ...data } }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function findReceipt(barcode) {
  return dispatch => {
    const url = prefixUrl(`/pos/receipts/search?barcode=${encodeURIComponent(barcode)}`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(({ data }) => data.receiptId)
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function fetchReceiptAccounting(posOrgId, receiptId) {
  return dispatch => {
    const url = prefixUrl(`/pos/receipts/org/${posOrgId}/receipts/${receiptId}/accounting-data`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function recreateReceiptAccounting(posOrgId, receiptId) {
  return dispatch => {
    const url = prefixUrl(`/pos/receipts/org/${posOrgId}/receipts/${receiptId}/accounting-data/recreate`);
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function fetchReports() {
  return dispatch => {
    const url = getReportsUrl('/z/');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(({ data }) => dispatch({ type: POS_REPORTS_FETCHED, reports: data.reports }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchReport(sequenceNo) {
  return dispatch => {
    const url = getReportsUrl(sequenceNo ? `/z/${sequenceNo}` : '/x/');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(({ data }) => dispatch({ type: POS_REPORT_FETCHED, report: data }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchXReportSummary() {
  return dispatch => {
    const url = getReportsUrl('/x/summary');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function sendReport(sequenceNo) {
  return dispatch => {
    const url = getReportsUrl(sequenceNo ? `/z/${sequenceNo}/email` : '/x/email');
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function calculateRefund(saleId, data) {
  return dispatch => {
    const url = getSalesUrl(`/sales/${saleId}/refund-options`);
    const config = axiosDefault();

    return axios.post(url, data, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(({ data }) => data)
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function sendRefund(saleId, payment) {
  return dispatch => {
    const url = getSalesUrl(`/sales/${saleId}/refund`);
    const config = axiosDefault();
    const { paymentMethod } = payment;

    return axios.post(url, payment, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(({ data }) => dispatch({ type: POS_PAYMENT_RESULT, ...data, paymentMethod }))
      .catch((error) => {
        if (payment.paymentMethod !== 'Card') {
          axiosErrorHandler(error, dispatch);
        }
        throw error;
      });
  };
}

export function openDrawer() {
  return dispatch => {
    const url = getVunitUrl('/open-drawer');
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function forceCreateReceipts(saleId) {
  return (dispatch) => {
    const url = getSalesUrl(`/sales/${saleId}/force-create-receipts`);
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function depositCash(amount, comment) {
  return dispatch => {
    const url = getVunitUrl('/deposit');
    const config = axiosDefault();

    return axios.post(url, { amount, comment }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function withdrawCash(amount, comment) {
  return dispatch => {
    const url = getVunitUrl('/withdraw');
    const config = axiosDefault();

    return axios.post(url, { amount, comment }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function getSwishQrCodeObjectUrl(payee, amount, message) {
  return axios.request({
    method: 'POST',
    url: 'https://api.swish.nu/qr/v2/prefilled',
    responseType: 'blob',
    data: {
      payee,
      amount: {
        value: amount,
        editable: false
      },
      message: message ? {
        value: message,
        editable: false
      } : undefined,
      size: 400,
      border: 0
    }
  }).then(({ data }) => URL.createObjectURL(new Blob([data])));
}

export function loginVunit(posOrgId, vunitId) {
  return (dispatch) => {
    const url = prefixUrl(`/pos/orgs/${posOrgId}/vunits/${vunitId}/login`);
    const config = axiosDefault();

    return axios.post(url, null, config)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function logoutVunit(posOrgId, vunitId) {
  return (dispatch) => {
    const url = prefixUrl(`/pos/orgs/${posOrgId}/vunits/${vunitId}/logout`);
    const config = axiosDefault();

    return axios.post(url, null, config)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function downloadReceiptSie(posOrgId, receiptId) {
  return (dispatch) => {
    const url = prefixUrl(`/pos/reports/sales/org/${posOrgId}/receipt/sie?receiptId=${receiptId}`);
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ url }) => window.open(url, '_self'))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function resetReceiptCopyCount(posOrgId, receiptId) {
  return (dispatch) => {
    const url = prefixUrl(`/pos/receipts/org/${posOrgId}/receipts/${receiptId}/reset-copy-count`);
    const config = axiosDefault();

    return axios.put(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: POS_RECEIPT_COPY_RESET, receiptId }))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}
