import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';

import {
  CUSTOMER_PAGE_SIZE,
  CUSTOMER_SEARCH_DEBOUNCE,
  CUSTOMER_SEARCH_MIN_LENGTH
} from '@Utils/constants';

import {
  searchCustomers,
  fetchCustomersList,
  deleteCustomer,
  customerClearForm
} from '@State/customer-actions';
import { fetchGroupsAndResources } from '@State/resource-actions';
import { getFeatures } from '@State/selectors';
import { CustomerType } from '@Utils/customer-util';

import BookingModals from '@Components/calendar/booking/booking-modals';
import CustomerDetail from '@Components/customers/customer-detail/customer-detail';
import CustomersList from '@Components/customers/customers-list';
import CustomerTypeSegment from '@Components/customers/customer-type-segment';
import CustomerListFooter from '@Components/customers/customer-list-footer';
import ColumnSearch from '@Components/ui/column/column-search';
import CustomerIdContext from '@Components/customers/customer-id-context';
import Loader from '@Components/ui/loader';
import { txt } from '@Utils/i18n-util';
import msg from './customers-list.msg';

const CustomersPage = ({ match }) => {
  const dispatch = useDispatch();
  const phoneMode = useSelector(state => state.mainViewState.get('phoneMode'));
  const { EnableCompanyBooking, EnableVehicleBooking } = useSelector(getFeatures);
  const [customerType, setCustomerType] = useState(CustomerType.Person);
  const showCustomerType = EnableCompanyBooking || EnableVehicleBooking;

  const onFetchCustomers = (limit, offset) => dispatch(fetchCustomersList([customerType], limit, offset));
  const onDeleteCustomer = (id) => dispatch(deleteCustomer(id));
  const onSearchCustomers = (text) => dispatch(searchCustomers(text, [customerType]));
  const onClearCustomerForm = () => dispatch(customerClearForm());
  const onFetchGroupsAndResources = () => dispatch(fetchGroupsAndResources());

  const routeParams = match.params;
  const customers = useSelector(state => state.customers);
  const resourcesById = useSelector(state => state.resourcesById);

  const [loading, setLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [searchText, setSearchText] = useState('');

  const customerId = parseInt(routeParams?.id);
  const customerEntries = customers.get('customerEntries');
  const totalCount = customers.get('totalCount');

  const onSearchCustomersDebounce = useCallback(debounce(onSearchCustomers, CUSTOMER_SEARCH_DEBOUNCE), [customerType]);

  const loadInitialCustomers = useCallback(async () => {
    setLoading(true);
    try {
      await onFetchCustomers(CUSTOMER_PAGE_SIZE);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (resourcesById && resourcesById.isEmpty()) {
      onFetchGroupsAndResources();
    }
    loadInitialCustomers();
  }, []);

  const handleConfirmDeleteCustomer = async () => {
    await onDeleteCustomer(customerId);
    onClearCustomerForm();
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handlePageClick = (selectedPage) => {
    setPageIndex(selectedPage);
  };

  useEffect(() => {
    if (searchText?.length > CUSTOMER_SEARCH_MIN_LENGTH) {
      onSearchCustomersDebounce(searchText);
    } else if (!searchText) {
      const offset = pageIndex * CUSTOMER_PAGE_SIZE;
      onFetchCustomers(CUSTOMER_PAGE_SIZE, offset);
    }
  }, [pageIndex, customerType, searchText]);

  const handleTypeChange = (selectedType) => {
    setPageIndex(0);
    setCustomerType(selectedType);
  };

  const pageCount = Math.ceil(totalCount / CUSTOMER_PAGE_SIZE);
  const showFooter = !searchText && pageCount > 1;
  const isShowForm = customerId;

  if (!phoneMode && customerEntries && !customerEntries.isEmpty() && !routeParams.id) {
    const cId = customerEntries.first().get('customerId');
    const { org, loc } = routeParams;
    return <Redirect to={`/${org}/${loc}/customers/${cId}/overview`} />;
  }

  if (loading && !customerEntries) {
    return (
      <div className="columns-wrapper">
        <Loader />
      </div>
    );
  }

  return (
    <div className="columns-wrapper">
      <div className="columns-container">
        <div className="columns-sidebar">
          <div className="columns-header mt1">
            <ColumnSearch
              searchValue={searchText}
              onSearch={handleSearch}
              placeholder={txt(msg.lblSearch)}
            />
          </div>
          {showCustomerType && (
            <div className="columns-header">
              <CustomerTypeSegment
                currentType={customerType}
                onTypeChange={handleTypeChange}
              />
            </div>
          )}
          <CustomersList
            routeParams={routeParams}
            customerEntries={customerEntries}
            selectedCustomerId={customerId}
          />
          {showFooter && (
            <CustomerListFooter
              pageCount={pageCount}
              handlePageClick={handlePageClick}
              pageIndex={pageIndex}
              totalCount={totalCount}
            />
          )}
        </div>
        {!phoneMode && (
          <div className="columns-content">
            <div className="columns-content-container width-medium">
              {isShowForm
                ? (
                  <CustomerIdContext.Provider value={+customerId}>
                    <CustomerDetail
                      routeParams={routeParams}
                      onConfirmDelete={handleConfirmDeleteCustomer}
                    />
                  </CustomerIdContext.Provider>
                )
                : null}
            </div>
          </div>
        )}
      </div>
      <BookingModals routeParams={routeParams} />
    </div>
  );
};

CustomersPage.propTypes = {
  onFetchCustomers: PropTypes.func,
  onDeleteCustomer: PropTypes.func,
  onUpdateCustomer: PropTypes.func,
  onCreateCustomer: PropTypes.func,
  onSearchCustomers: PropTypes.func,
  onFetchGroupsAndResources: PropTypes.func
};

export default CustomersPage;
