import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { getCustomerDetailsPermission } from '@State/selectors';
import { FormGroup, Column, Text, Button } from '@Components/ui/styled/main';
import {
  PanelWrapper, TitleBlock, StyledLink, RowFormGroups
} from '@Components/customers/customer-styles';
import { formatPhoneNumberE164, formatPhoneNumber } from '@Utils/phone-util';
import { CustomerType, getGenderText } from '@Utils/customer-util';
import { updateCustomerInfo, updateCustomerAddress } from '@State/customer-actions';
import CustomerEditModal from '@Components/customers/customer-detail/overview/customer-edit-modal';
import { txt } from '@Utils/i18n-util';
import msg from './customer-information.msg';

const CustomerInformation = ({ routeParams, customerId, selectedCustomer }) => {
  const dispatch = useDispatch();
  const hasCustomerDetailsPermission = useSelector(state => getCustomerDetailsPermission(state, { routeParams }));

  const {
    addressLine1, addressLine2, addressLine3, area, postCode, country,
    name, phoneNumber, otherPhoneNumber, email, officialIdNo, gender,
    customerType, officialIdType, id
  } = selectedCustomer || {};

  const initialInformationData = {
    customerType,
    name,
    email,
    gender,
    officialIdNo,
    officialIdType,
    phoneNumber: formatPhoneNumber(phoneNumber),
    otherPhoneNumber: formatPhoneNumber(otherPhoneNumber)
  };

  const initialAddressData = {
    addressLine1,
    addressLine2,
    addressLine3,
    area,
    postCode,
    country
  };

  const [isOpenChangeModal, setOpenChangeModal] = useState(false);
  const openModalChangeInfo = () => hasCustomerDetailsPermission && setOpenChangeModal(true);
  const hideModalChangeInfo = () => hasCustomerDetailsPermission && setOpenChangeModal(false);

  const onSubmitInfo = (newInfo) => {
    dispatch(updateCustomerInfo(id || customerId, newInfo));
  };

  const onSubmitAddress = (newAddress) => {
    dispatch(updateCustomerAddress(id || customerId, newAddress));
  };

  const onSubmit = (values) => {
    const newAddress = {
      addressLine1: values.addressLine1 || '',
      addressLine2: values.addressLine2,
      addressLine3: values.addressLine3,
      area: values.area || '',
      postCode: values.postCode || '',
      country: values.country || ''
    };
    const newInfo = {
      customerType: values.customerType,
      name: values.name,
      email: values.email,
      gender: values.gender,
      officialIdNo: values.officialIdNo,
      officialIdType: values.officialIdNo ? values.officialIdType : null,
      phoneNumber: values.phoneNumber,
      otherPhoneNumber: values.otherPhoneNumber
    };

    if (!isEqual(initialInformationData, newInfo)) {
      onSubmitInfo({
        ...newInfo,
        phoneNumber: newInfo.phoneNumber ? formatPhoneNumberE164(newInfo.phoneNumber) : null,
        otherPhoneNumber: newInfo.otherPhoneNumber ? formatPhoneNumberE164(newInfo.otherPhoneNumber) : null
      });
    }
    if (!isEqual(initialAddressData, newAddress)) {
      onSubmitAddress(newAddress);
    }
    hideModalChangeInfo();
  };

  const isEmptyContent = !name && !phoneNumber && !otherPhoneNumber && !email && !officialIdNo;
  const isShowAddress = addressLine1 || addressLine2 || addressLine3;
  const isShowFullAddress = (isShowAddress || postCode || country || area);
  const isPerson = customerType === CustomerType.Person;
  const isCompany = customerType === CustomerType.Company;
  const isVehicle = customerType === CustomerType.Vehicle;

  return (
    <>
      {isOpenChangeModal && (
        <CustomerEditModal
          customer={selectedCustomer}
          hideModalChangeInfo={hideModalChangeInfo}
          initialData={{ ...initialInformationData, ...initialAddressData }}
          onSubmit={onSubmit}
        />
      )}
      <TitleBlock>{txt(msg.lblCustomerDetails)}</TitleBlock>
      <PanelWrapper onClick={openModalChangeInfo}>
        <RowFormGroups>
          <Column>
            {isVehicle ? (
              <FormGroup
                labelText={txt(msg.lblVehicleRegNo)}
                text={officialIdNo || '-'}
              />
            ) : (
              <FormGroup
                labelText={isCompany ? txt(msg.lblCompanyName) : txt(msg.lblName)}
                text={name || '-'}
              />
            )}
          </Column>
          <Button white tiny>{txt(msg.btnEdit)}</Button>
        </RowFormGroups>
        {isCompany && officialIdNo && (
          <RowFormGroups>
            <Column>
              <FormGroup
                labelText={txt(msg.lblOrgNo)}
                text={officialIdNo}
              />
            </Column>
          </RowFormGroups>
        )}
        {isVehicle && name && (
          <RowFormGroups>
            <Column>
              <FormGroup
                labelText={txt(msg.lblDescription)}
                text={name}
              />
            </Column>
          </RowFormGroups>
        )}
        {(phoneNumber || otherPhoneNumber) && (
        <RowFormGroups>
          {phoneNumber
            && (
            <Column>
              <FormGroup
                labelText={txt(msg.lblPhoneNumber)}
                text={initialInformationData.phoneNumber}
              />
            </Column>
            )}
          {otherPhoneNumber && (
          <Column>
            <FormGroup
              labelText={txt(msg.lblOtherPhoneNumber)}
              text={initialInformationData.otherPhoneNumber}
            />
          </Column>
          )}
        </RowFormGroups>
        )}
        {email && (
        <RowFormGroups>
          <Column>
            <FormGroup
              labelText={txt(msg.lblEmail)}
              text={email}
            />
          </Column>
        </RowFormGroups>
        )}
        {isPerson && (!!officialIdNo || gender && gender !== 'None') && (
        <RowFormGroups>
          {officialIdNo && (
          <Column>
            <FormGroup
              labelText={txt(msg.lblPersonalIDNo)}
              text={officialIdNo + (officialIdType ? ` (${officialIdType})` : '')}
            />
          </Column>
          )}
          {gender && gender !== 'None' && (
          <Column>
            <FormGroup
              labelText={txt(msg.lblGender)}
              text={getGenderText(gender)}
            />
          </Column>
          )}
        </RowFormGroups>
        )}
        {isShowFullAddress && (
        <RowFormGroups>
          <Column>
            <FormGroup
              labelText={txt(msg.lblAddress)}
              text={(
                <>
                  {addressLine1 && <Text fs={13}>{addressLine1}</Text>}
                  {addressLine2 && <Text fs={13}>{addressLine2}</Text>}
                  {addressLine3 && <Text fs={13}>{addressLine3}</Text>}
                  {postCode || area ? (
                    <Text fs={13}>
                      {`${postCode || ''} ${area || ''}`.trim()}
                    </Text>
                  ) : null}
                  {country && <Text fs={13}>{country}</Text>}
                </>
              )}
            />
          </Column>
        </RowFormGroups>
        )}
        {isEmptyContent && <StyledLink>{txt(msg.lblAddCustomerDetails)}</StyledLink>}
      </PanelWrapper>
    </>
  );
};

export default CustomerInformation;
