import moment from 'moment';
import React from 'react';
import s from 'underscore.string';
import Highlighter from 'react-highlight-words';
import PhoneUtil from '@Utils/phone-util';
import { styles } from '@Components/calendar/grid/chip-styles';
import { getPrimaryCustomer, getCompanyCustomer, getVehicleCustomer } from '@Utils/booking-util';

const SearchResultItem = ({
  booking, visualStyles, onClick, selectedBookingId, search,
  locationOptions, currentLocation, orgWideCustomerDb
}) => {
  const {
    id, startTime, status, locationId, resources, services
  } = booking;
  const customer = getPrimaryCustomer(booking);
  const company = getCompanyCustomer(booking);
  const vehicle = getVehicleCustomer(booking);

  const { name, phoneNumber, otherPhoneNumber, email } = customer || {};
  const { name: orgName, officialIdNo: orgNo } = company || {};
  const { officialIdNo: vehicleRegNo, name: vehicleDescription } = vehicle || {};

  const resourcesDeleted = resources.every(r => r.deleted);
  const phoneNumbers = [];
  if (phoneNumber) {
    phoneNumbers.push(PhoneUtil.formatPhoneNumber(phoneNumber));
  }
  if (otherPhoneNumber) {
    phoneNumbers.push(PhoneUtil.formatPhoneNumber(otherPhoneNumber));
  }
  const isOtherLocation = locationId !== currentLocation.locationId;
  const selected = id === selectedBookingId;
  const selectedStyle = selected ? visualStyles.statuses[`${status}Drag`] : null;
  const handStyle = (resourcesDeleted ? {} : { cursor: 'pointer' });
  const chipStyles = {
    ...styles.chips.base,
    ...visualStyles.statuses[status],
    ...selectedStyle,
    ...handStyle
  };

  const locationName = isOtherLocation
    ? locationOptions.valueSeq().find(l => l.locationId === locationId).locName
    : currentLocation.locName;

  return (
    <div
      className={resourcesDeleted ? 'search-result deleted' : 'search-result'}
      onClick={resourcesDeleted ? null : onClick}
      style={chipStyles}
    >
      <section>
        <strong>{name}</strong>
        <div>
          {services?.map(({ id, name }, index) => (
            <span key={id}>
              {index > 0 ? ', ' : ''}{name}
            </span>
          ))}
        </div>
        <div>
          {resources.map(({ id, name, deleted }, index) => (
            <span key={id} className={deleted ? 'text-danger' : ''}>
              {index > 0 ? ', ' : ''}{name}{deleted && ' (borttagen)'}
            </span>
          ))}
        </div>
        <span>{s.capitalize(moment(startTime).format('LLLL'))}</span>
      </section>
      {(vehicleRegNo || vehicleDescription) && (
        <section>
          {vehicleRegNo && <div><Highlighter searchWords={search} textToHighlight={vehicleRegNo} autoEscape /></div>}
          {vehicleDescription && <div><Highlighter searchWords={search} textToHighlight={vehicleDescription} autoEscape /></div>}
        </section>
      )}
      {(orgName || orgNo) && (
        <section>
          {orgName && <div><Highlighter searchWords={search} textToHighlight={orgName} autoEscape /></div>}
          {orgNo && <small><Highlighter searchWords={search} textToHighlight={orgNo} autoEscape /></small>}
        </section>
      )}
      {(name || phoneNumbers.length > 0 || email) && (
        <section>
          {phoneNumbers.length > 0 && <div><Highlighter searchWords={search} textToHighlight={phoneNumbers.join(' / ')} autoEscape /></div>}
          {email && <div><Highlighter searchWords={search} textToHighlight={email} autoEscape /></div>}
        </section>
      )}
      {orgWideCustomerDb && (
        <section className="other-location">
          <strong>
            {locationName}
&nbsp;&nbsp;
            {isOtherLocation && (<small className="pull-right"><i className="fa fa-external-link" /></small>)}
          </strong>
        </section>
      )}
    </div>
  );
};

export default SearchResultItem;
