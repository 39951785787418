import React, { memo, useEffect } from 'react';
import { getDiscountAmount, getDiscountDescription, getItemDescription, LowStockThreshold } from '@Utils/pos-utils';
import CurrencyUtil from '@Utils/currency-util';
import {
  SaleItemBlock, SaleItemQuantity, SaleItemContent, SaleItemContentRow, SaleItemStockWarning
} from '@Components/pos/style';
import { useSelector } from 'react-redux';
import { pos } from '@Utils/preference-keys';
import { getOperatorId, getPosUnitPrefs } from '@State/pos-selectors';
import { txt } from '@Utils/i18n-util';
import msg from './pos-sale.msg';

const SaleItem = ({
  partialPaid, grossAmount, item, products, showLowStock, color, onClick, onRemove
}) => {
  const prefs = useSelector(getPosUnitPrefs);
  const showVoucherCode = prefs[pos.showVoucherCodeOnReceipt];
  const cashiersById = useSelector(state => state.cashiersById);
  const operatorId = useSelector(getOperatorId);

  const {
    id, sellerId, itemType, description, quantity, amount, discountType, discountValue, comment,
    voucherBeneficiary, voucherCode, showEditModal
  } = item;

  const sellerName = operatorId !== sellerId
    ? cashiersById.get(sellerId)?.get('name')
    : null;

  useEffect(() => {
    if (id && showEditModal && !partialPaid) {
      onClick(item);
    }
  }, [id, showEditModal]);

  const isDiscount = itemType === 'Discount';
  const isUserCanClick = !partialPaid;
  const isDisabledBtnRemove = !id;
  const totalAmount = isDiscount
    ? -getDiscountAmount(discountType, discountValue, grossAmount)
    : quantity * amount;
  const totalAmountValue = CurrencyUtil.accountCurrency(totalAmount, 2);
  const discountDescription = getDiscountDescription(discountValue, discountType);
  const percentValue = CurrencyUtil.accountCurrency(-getDiscountAmount(discountType, discountValue, quantity * amount), 2);
  const text = isDiscount ? description : getItemDescription(description, itemType);

  const articleId = parseInt(item.articleId || 0);
  const product = articleId ? products.get(articleId) : null;
  const lowStock = showLowStock && product?.stocked && product?.stockQuantity <= LowStockThreshold;
  const stockQuantity = showLowStock && product?.stockQuantity;

  const handleStopEvent = (ev) => ev.stopPropagation();
  const handleOnClickItem = () => isUserCanClick && onClick(item);
  const handleRemove = () => onRemove(id);

  const showDiscount = !isDiscount && discountValue > 0;

  return (
    <SaleItemBlock disabled={partialPaid} onClick={handleOnClickItem} color={color}>
      <SaleItemQuantity>{!isDiscount && quantity}</SaleItemQuantity>
      <SaleItemContent>
        <SaleItemContentRow>
          <div>
            {text}
            {lowStock && (
              <SaleItemStockWarning>
                <i className="fa fa-exclamation-circle" />
                {stockQuantity > 0
                  ? txt(msg.lblFewInStock, { stockQuantity })
                  : txt(msg.lblNoneInStock)}
              </SaleItemStockWarning>
            )}
          </div>
          <div>{totalAmountValue}</div>
        </SaleItemContentRow>
        {sellerName && (
          <SaleItemContentRow subItem>
            <p>{txt(msg.lblSeller)}: {sellerName}</p>
          </SaleItemContentRow>
        )}
        {showDiscount && (
          <SaleItemContentRow subItem>
            <span>{discountDescription}</span>
            <span>{percentValue}</span>
          </SaleItemContentRow>
        )}
        {comment && (
          <SaleItemContentRow subItem>
            <p>{comment}</p>
          </SaleItemContentRow>
        )}
        {voucherBeneficiary && (
          <SaleItemContentRow subItem>
            <p>{voucherBeneficiary}</p>
          </SaleItemContentRow>
        )}
        {showVoucherCode && voucherCode && (
          <SaleItemContentRow subItem>
            <p>{voucherCode}</p>
          </SaleItemContentRow>
        )}
      </SaleItemContent>
      <div>
        {!partialPaid
          && (
            <span onClick={handleStopEvent}>
              <button className="btn btn-default" disabled={isDisabledBtnRemove} onClick={handleRemove}>
                <i className="fad fa-trash" />
              </button>
            </span>
          )}
      </div>
    </SaleItemBlock>
  );
};

export default memo(SaleItem);
