import moment from 'moment/moment';
import { isoWeekdays } from '@Utils/schedule-utils';

const dateFormat = 'YYYYMMDDTHHmmss[Z]';

export function getRruleDays() {
  return Array.from({ length: 7 }, (x, i) => {
    const weekDay = moment().weekday(i);
    return {
      day: isoWeekdays[weekDay.isoWeekday()].substring(0, 2),
      name: weekDay.format('dd')
    };
  });
}

export function getInitialValuesFromRruleString(rrule) {
  const rules = {};
  rrule?.split(';').forEach(pair => {
    const [key, value] = pair.split('=');
    rules[key] = value;
  });

  const values = {
    repeat: rules.FREQ || 'WEEKLY',
    interval: rules.INTERVAL || 1,
    end: 'COUNT'
  };
  if (rules.BYDAY) {
    values.days = rules.BYDAY.split(',');
  }
  if (rules.COUNT) {
    values.end = 'COUNT';
    values.count = rules.COUNT || 1;
  }
  if (rules.UNTIL) {
    values.end = 'UNTIL';
    values.until = moment(rules.UNTIL, dateFormat);
  }
  return values;
}

export function getRruleStringFromForm(values) {
  const { repeat, interval, days, end, until, count } = values || {};

  const rules = [
    `FREQ=${repeat || 'WEEKLY'}`,
    `INTERVAL=${interval || 1}`
  ];
  if (days) {
    rules.push(`BYDAY=${days.join(',')}`);
  }
  if (end === 'COUNT') {
    rules.push(`COUNT=${count || 1}`);
  }
  if (end === 'UNTIL') {
    rules.push(`UNTIL=${moment(until).utc().format(dateFormat)}`);
  }
  return rules.join(';');
}
