import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import Modal from '@Components/ui/modal';
import { updateCustomValues, deleteCustomValues, serviceMappingValues } from '@State/services-actions';
import CustomServiceSettingsForm from './custom-service-settings-form';

const stylesDialog = { width: 400 };
class CustomServiceSettingsModal extends Component {
  static propTypes = {
    serviceMapping: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  render() {
    const { closeModal, serviceMapping, serviceFormValues, isAddonService, showInBooking, hasCustomDiffs } = this.props;
    const resource = serviceMapping.get('resource');

    const initialValues = {
      customValues: serviceMapping.get('customValues'),
      serviceDuration: serviceMapping.get('serviceDuration'),
      afterTime: serviceMapping.get('afterTime'),
      price: serviceMapping.get('price'),
      priceEvening: serviceMapping.get('priceEvening'),
      priceHoliday: serviceMapping.get('priceHoliday'),
      priceFrom: serviceMapping.get('priceFrom'),
      useDynamicPricing: serviceMapping.get('useDynamicPricing'),
      webAllowBooking: showInBooking && serviceMapping.get('webAllowBooking'),
      webShowDuration: serviceMapping.get('webShowDuration'),
      webShowPrice: serviceMapping.get('webShowPrice')
    };

    return (
      <Modal
        titleText="Inställningar"
        underlayClickExits
        dialogClass="modal-dialog"
        dialogStyle={stylesDialog}
        mounted={this.state.showForm}
        initialFocus=".modal-content"
        onExit={this.props.closeModal}
      >
        <div className="modal-content">
          <div className="modal-body">
            <h4>
              Inställningar för {resource.name}
            </h4>
            <CustomServiceSettingsForm
              onClose={closeModal}
              onSubmit={this.handleSubmit}
              initialValues={initialValues}
              serviceFormValues={serviceFormValues}
              isAddonService={isAddonService}
              showInBooking={showInBooking}
              hasCustomDiffs={hasCustomDiffs}
            />
          </div>
        </div>
      </Modal>
    );
  }

  handleSubmit = (values) => {
    const { serviceMapping } = this.props;
    const { serviceId, resource } = serviceMapping.toJS();

    if (values.customValues) {
      return this.props.updateCustomValues(serviceId, resource.id, values)
        .then(() => this.props.closeModal());
    }
    return this.props.deleteCustomValues(serviceId, resource.id)
      .then(() => this.props.closeModal());
  };
}

const hasDiffs = (values, mappings) => {
  if (!values || !mappings.customValues) {
    return false;
  }
  for (let i = 0; i < serviceMappingValues.length; i++) {
    const prop = serviceMappingValues[i];
    if (String(values[prop]) !== String(mappings[prop])) {
      return true;
    }
  }
};

const mapStateToProps = (state) => {
  const serviceFormValues = getFormValues('edit-service-form')(state);
  const mappingFormValues = getFormValues('custom-service-settings-form')(state);
  const hasCustomDiffs = mappingFormValues && hasDiffs(serviceFormValues, mappingFormValues);

  return {
    serviceFormValues,
    hasCustomDiffs
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCustomValues: (serviceId, resourceId, customValues) => {
      return dispatch(updateCustomValues(serviceId, resourceId, customValues));
    },
    deleteCustomValues: (serviceId, resourceId) => {
      return dispatch(deleteCustomValues(serviceId, resourceId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomServiceSettingsModal);
