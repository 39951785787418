import moment from 'moment';
import CurrencyUtil from '@Utils/currency-util';
import { formatPhoneNumber } from '@Utils/phone-util';
import { formatOrgNo } from '@Utils/luhn-util';

export const historyEventAction = {
  CREATE_BOOKING: 'Skapad',
  CONFIRM_BOOKING: 'Skapad',
  CHANGE_BOOKING_DETAILS: 'Ändrad',
  CHANGE_BOOKING_STATUS: 'Ändrad',
  SET_BOOKING_FLAG: 'Ändrad',
  CANCEL_BOOKING: 'Avbokad',
  MOVE_BOOKING: 'Ändrad',
  IMPORT_BOOKING: 'Importerad',
  IMPORT_CUSTOMER: 'Importerad',
  BOOKING_PAID: 'Betald',
  BOOKING_REFUNDED: 'Återbetald',
  REFUND_BOOKING: 'Återbetald',
  ADD_CUSTOMER: 'Skapad',
  MODIFY_CUSTOMER: 'Ändrad',
  REMINDER_SENT: 'Påminnelse skickad',
  CONFIRMATION_SENT: 'Bekräftelse skickad',
  REVIEW_REMINDER_SENT: 'Fråga om omdöme skickad',
  REVIEW_REMINDER_POSTED: 'Omdöme lämnat',
  ADD_BOOKING_CUSTOMER: 'Kund tillagd',
  DELETE_BOOKING_CUSTOMER: 'Kund borttagen'
};

export const historyEventKey = {
  resourceName: 'Resurs',
  primaryResource: 'Primär resurs',
  secondaryResource: 'Sekundär resurs',
  associatedResourceName: 'Hör till resurs',
  startTime: 'Tidpunkt',
  endTime: 'Sluttid',
  duration: 'Längd',
  afterTime: 'Paus efter',
  price: 'Pris',
  description: 'Beskrivning',
  note: 'Anteckning',
  notes: 'Anteckning',
  AskedForPerson: 'Bokat person',
  DropIn: 'Drop in',
  status: 'Status',
  companyId: 'Företags.nr.',
  companyName: 'Företag',
  orgName: 'Företag',
  orgNo: 'Org.nr.',
  customerId: 'Kund.nr.',
  name: 'Namn',
  customerName: 'Namn',
  email: 'Mejladress',
  sms: 'Mobiltelefon',
  customerEmail: 'Mejladress',
  phoneNumber: 'Mobiltelefon',
  customerPhoneNumber: 'Mobiltelefon',
  otherPhoneNumber: 'Annan telefon',
  customerOtherPhoneNumber: 'Annan telefon',
  customerPno: 'Personnummer',
  personalIDNo: 'Personnummer',
  gender: 'Kön',
  vehicleRegNo: 'Reg.nr.',
  vehicleDescription: 'Fordon',
  addressLine1: 'Address',
  addressLine2: 'Address rad 2',
  addressLine3: 'Address rad 3',
  postCode: 'Postnummer',
  area: 'Stad',
  country: 'Land',
  ownedByLocation: 'Hör till kontot',
  smsReminders: 'SMS-påminnelser',
  allowMarketing: 'Marknadsföring OK',
  sendReviewRequest: 'Omdömesförfrågan OK',
  blockWebBooking: 'Blockera bokning'
};

export function getSortedKeys(data = {}) {
  const keys = [];
  Object.keys(historyEventKey).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      keys.push(key);
    }
  });
  Object.keys(data).forEach((key) => {
    if (keys.indexOf(key) === -1) {
      keys.push(key);
    }
  });
  return keys;
}

function channelText(channel) {
  switch (channel) {
    case 'Cal':
      return 'i kalender';
    default:
      return 'via webb';
  }
}

export function getUserOrChannel(user, channel) {
  if (user) {
    return `av ${user}`;
  }
  if (channel) {
    return channelText(channel);
  }
  return 'av system';
}

function statusText(status) {
  switch (status) {
    case 'Show':
      return 'Show';
    case 'NoShow':
      return 'No show';
    case 'Cancelled':
      return 'Avbokad';
    default:
      return '';
  }
}

function genderText(status) {
  switch (status) {
    case 'Male':
      return 'Man';
    case 'Female':
      return 'Kvinna';
    default:
      return '';
  }
}

export function historyEventValue(key, value, other) {
  if (value === true) {
    return 'På';
  }
  if (value === false) {
    return 'Av';
  }
  if (key === 'startTime' || key === 'endTime') {
    const time = moment(value);
    return other && time.isSame(other, 'd')
      ? time.format('LT')
      : time.format('llll');
  }
  if (key === 'afterTime' || key == 'duration') {
    return `${parseInt(value || 0)} min`;
  }
  if (key === 'price') {
    return CurrencyUtil.accountCurrency(value);
  }
  if (key === 'status') {
    return statusText(value);
  }
  if (key === 'gender') {
    return genderText(value);
  }
  if (key === 'phoneNumber' || key === 'otherPhoneNumber' || key === 'sms'
    || key === 'customerPhoneNumber' || key === 'customerOtherPhoneNumber') {
    return formatPhoneNumber(value);
  }
  if (key === 'orgNo') {
    return formatOrgNo(value);
  }
  if (key === 'secondaryResource') {
    return value.split(',').join(', ');
  }
  return value;
}
