import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import {
  ScrollContent, ScrollWrapper, ColumnRight,
  ColumnLeft, TextMuted, TitleBlock, TitleHeader, CustomerFutureWrapper
} from '@Components/customers/customer-styles';

import CustomerFutureBookings from '@Components/customers/customer-detail/bookings/customer-future-bookings';
import CustomerInformation from '@Components/customers/customer-detail/overview/customer-information';
import CustomerDetailControl from '@Components/customers/customer-detail/overview/customer-detail-control';
import CustomerDetailOwner from '@Components/customers/customer-detail/overview/customer-detail-owner';
import CustomerNotes from '@Components/customers/customer-detail/overview/customer-notes';
import CustomerEmptyTab from '@Components/customers/customer-detail/customer-empty-tab';
import CustomerDetailMonthList from '@Components/customers/customer-detail/customer-detail-month-list';
import CustomerInvoiceCustomer from '@Components/customers/customer-detail/overview/customer-invoice-customer';
import CustomerCompany from '@Components/customers/customer-detail/overview/customer-company';
import CustomerContact from '@Components/customers/customer-detail/overview/customer-contact';
import HistoryButton from '@Components/customers/history-button';
import Loader from '@Components/ui/loader';
import Row from '@Components/ui/styled/row';

import { fetchCustomerLogEntries, fetchCustomerSales, fetchBookingsHistory } from '@State/customer-actions';
import { openCustomerTab } from '@State/cf-actions';
import { getFullTimeCreated } from '@Utils/time-util';
import { getSortedBookings, groupEventsByMonth, getFutureSortedBookings, CustomerType, isCustomerPerson, isCustomerVehicle } from '@Utils/customer-util';
import { getFeatures } from '@State/selectors';
import {
  navigate, getCustomerSalesUrl, getCustomerBookingUrl, getCustomerLogsUrl
} from '@Utils/navigate';
import { txt } from '@Utils/i18n-util';
import msg from './customer-detail-overview.msg';

const CustomerDetailOverview = ({
  modalView, routeParams, isLoadingCustomer, selectedCustomer, customerId
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBookingsHistory(customerId));
    dispatch(fetchCustomerLogEntries(customerId));
    dispatch(fetchCustomerSales(customerId));
  }, [customerId]);

  const { EnablePOS, EnableCompanyBooking } = useSelector(getFeatures);
  const posOrgs = useSelector(state => state.posOrgs);
  const phoneMode = useSelector(state => state.mainViewState.get('phoneMode'));
  const isPerson = isCustomerPerson(selectedCustomer);
  const isVehicle = isCustomerVehicle(selectedCustomer);
  const showCompany = EnableCompanyBooking && (isPerson || isVehicle);
  const showInvoice = EnablePOS && !posOrgs.isEmpty() && isPerson;

  const customerBookings = useSelector((state) => state.customerBookings);
  const bookingLoading = customerBookings.get('loading');
  const bookings = customerBookings.get('bookings').toJS();

  const customerLogEntries = useSelector((state) => state.customerLogEntries);
  const logsLoading = customerLogEntries.get('loading');
  const logs = customerLogEntries.get('logs')?.toJS();

  const customerSales = useSelector((state) => state.customerSales);
  const salesLoading = customerSales.get('loading');
  const sales = customerSales.get('sales');

  const emptyEvents = isEmpty(bookings) && isEmpty(logs) && isEmpty(sales);
  const loadingEvents = bookingLoading || logsLoading || salesLoading;

  const futureBookings = bookings && getFutureSortedBookings(bookings);
  const pastBookings = getSortedBookings(bookings, false);

  let events = [];
  if (!emptyEvents) {
    const allEvents = [
      ...sales.map((item) => ({ ...item, typeData: 'sale' })),
      ...logs.map((item) => ({ ...item, typeData: 'logs' })),
      ...pastBookings.map((item) => ({ ...item, typeData: 'bookings' }))
    ];

    if (!isEmpty(allEvents)) {
      events = groupEventsByMonth(allEvents);
    }
  }

  const handlerSelectBooking = ({ id }) => {
    if (modalView) {
      dispatch(openCustomerTab({ tab: 'bookings', tabId: id }));
    } else {
      navigate(getCustomerBookingUrl(routeParams, id));
    }
  };
  const handlerOnClickSales = (id) => {
    if (modalView) {
      dispatch(openCustomerTab({ tab: 'sales', tabId: id }));
    } else {
      navigate(getCustomerSalesUrl(routeParams, id));
    }
  };
  const handlerSelectLogs = (id) => {
    if (modalView) {
      dispatch(openCustomerTab({ tab: 'logs', tabId: id }));
    } else {
      navigate(getCustomerLogsUrl(routeParams, id));
    }
  };

  const { customerType, created, lastUpdate, notes } = selectedCustomer || {};

  const renderCustomerInformation = () => (
    <>
      <CustomerNotes customerId={customerId} notes={notes} />
      <CustomerInformation routeParams={routeParams} selectedCustomer={selectedCustomer} customerId={customerId} />
      {showInvoice && <CustomerInvoiceCustomer routeParams={routeParams} selectedCustomer={selectedCustomer} customerId={customerId} />}
      {showCompany && <CustomerCompany routeParams={routeParams} selectedCustomer={selectedCustomer} customerId={customerId} />}
      {!isPerson && <CustomerContact routeParams={routeParams} selectedCustomer={selectedCustomer} customerId={customerId} />}
      {customerType === CustomerType.Person && (
        <>
          <TitleBlock>{txt(msg.lblSettings)}</TitleBlock>
          <CustomerDetailOwner selectedCustomer={selectedCustomer} />
          <CustomerDetailControl selectedCustomer={selectedCustomer} />
        </>
      )}
    </>
  );

  const renderEvents = () => (
    <>
      {futureBookings?.length > 0 && (
      <CustomerFutureWrapper>
        <TitleHeader>{txt(msg.lblUpcomingBookings)}</TitleHeader>
        <CustomerFutureBookings
          isSelectable
          items={futureBookings}
          onSelectEvent={handlerSelectBooking}
        />
      </CustomerFutureWrapper>
      )}
      {events?.length > 0 && (
      <>
        <TitleHeader>{txt(msg.lblHistory)}</TitleHeader>
        <CustomerDetailMonthList
          handlerOnClickSales={handlerOnClickSales}
          handlerOnClickBookings={handlerSelectBooking}
          handlerOnSelectLogs={handlerSelectLogs}
          events={events}
        />
      </>
      )}
    </>
  );

  const renderEmptyEvent = () => (
    <CustomerEmptyTab
      title={txt(msg.noEventsTitle)}
      text={txt(msg.noEventsText)}
    />
  );

  const renderFooter = () => (
    <div className="mt4">
      {created && (
      <TextMuted>
        {txt(msg.lblCreated)} {getFullTimeCreated(created)}
      </TextMuted>
      )}
      {lastUpdate && (
      <TextMuted>
        {txt(msg.lblUpdated)} {getFullTimeCreated(lastUpdate)}
      </TextMuted>
      )}
      <HistoryButton customerId={customerId} />
    </div>
  );

  if (phoneMode) {
    return (
      <Row scrollContainer>
        <ScrollWrapper>
          <ScrollContent>
            {(isLoadingCustomer || loadingEvents) ? <Loader topPosition />
              : (
                <>
                  {renderCustomerInformation()}
                  {emptyEvents ? renderEmptyEvent() : renderEvents()}
                </>
              )}
            {renderFooter()}
          </ScrollContent>
        </ScrollWrapper>
      </Row>
    );
  }
  return (
    <Row scrollContainer>
      <ColumnLeft>
        {isLoadingCustomer
          ? <Loader topPosition />
          : (
            <ScrollWrapper>
              <ScrollContent>
                {renderCustomerInformation()}
                {renderFooter()}
              </ScrollContent>
            </ScrollWrapper>
          )}
      </ColumnLeft>
      <ColumnRight>
        {loadingEvents && <Loader topPosition />}
        {!loadingEvents && (emptyEvents
          ? renderEmptyEvent()
          : (
            <ScrollWrapper>
              <ScrollContent>
                {renderEvents()}
              </ScrollContent>
            </ScrollWrapper>
          )
        )}
      </ColumnRight>
    </Row>
  );
};

CustomerDetailOverview.propTypes = {
  created: PropTypes.string,
  lastUpdate: PropTypes.string
};

export default CustomerDetailOverview;
