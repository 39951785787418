import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import merge from 'lodash/merge';
import { FormattedNumber } from 'react-intl';
import {
  bookingColumnsSelector, reportsQuerySelector,
  channelText, statusText, flagsText, getUserClientPreferences
} from '@Components/reports/reports-helpers';
import { getRouteParams } from '@State/selectors';
import { formatPhoneNumber } from '@Utils/phone-util';
import { formatVehicleRegNo } from '@Utils/vehicle-util';
import bookingUtilMsg from '@Utils/booking-util.msg';
import { txt } from '@Utils/i18n-util';
import { reports } from '@Utils/preference-keys';

class BookingList extends Component {
  render() {
    const { reportsViewState } = this.props;
    const bookingList = reportsViewState.get('bookingList');

    return bookingList && !bookingList.isEmpty()
      ? this.renderBookings(bookingList)
      : this.renderNoData(bookingList);
  }

  renderBookings(bookingList) {
    const {
      enableVehicleBooking, enableCompanyBooking, hideCancelledBookings, hideReservations, columnSettings
    } = this.props;
    const columnsData = columnSettings.filter((item) => item.checked);

    let count = 0,
      sum = 0,
      cancelledCount = 0;

    const bookingsData = bookingList.sortBy(b => b.get('startTime'));

    const isNeedUpdateHeader = columnsData.find((item) => item.id === 'isShowCustomFields');
    if (isNeedUpdateHeader) {
      columnsData.pop();
      const uniqCustomFields = [];
      bookingsData.map((book) => merge(uniqCustomFields, book.getIn(['customers', 0, 'customFields'])?.toJS()));
      if (uniqCustomFields.length > 0) {
        columnsData.push(...uniqCustomFields.map(({ label, ...field }) => ({ name: label, ...field })));
      }
    }

    return (
      <table className="table table-condensed">
        <thead>
          <tr>{columnsData.map(({ name }, index) => (<th key={index}>{name}</th>))}</tr>
        </thead>
        {bookingsData.map((booking, index) => {
          const {
            startTime, description, services, customers, vehicleRegNo, orgName,
            status, note, type, reservationType
          } = booking.toJS();
          const customer = customers?.length === 1 ? customers[0] : null;
          const { name, phone, price, channel, customFields } = customer || {};

          const isCancelled = status === 'Cancelled';
          const isReservation = type === 'Reservation';

          if (isCancelled && hideCancelledBookings) {
            return null;
          }
          if (isReservation && hideReservations) {
            return null;
          }

          const date = moment(startTime).format('LLLL');
          let service = description || '';
          if (services && services.length > 0) {
            service = services[0].name;
          }

          if (!isCancelled) {
            sum += parseInt(price || 0);
            if (!isReservation) {
              count++;
            }
          } else {
            cancelledCount++;
          }

          const noteRow = note ? <tr className="no-top-border"><td colSpan="7"><em>{note}</em></td></tr> : null;
          const reservationTypeText = reservationType && bookingUtilMsg[reservationType]
            ? txt(bookingUtilMsg[reservationType])
            : null;

          const dataRow = {
            isShowDate: date,
            isShowService: isReservation ? reservationTypeText : service,
            isShowAward: price ? (<FormattedNumber style="currency" currency="SEK" value={price} />) : '-',
            isShowCustomer: name,
            isShowPhone: formatPhoneNumber(phone),
            isShowSource: isReservation ? null : channelText(channel),
            isShowStatus: isReservation ? null : statusText(status),
            isShowOther: this.bookingFlagsText(booking),
            isShowVehicleRegNo: enableVehicleBooking && formatVehicleRegNo(vehicleRegNo),
            isShowBusiness: enableCompanyBooking && orgName
          };

          const defaultColumnsChecked = columnsData.filter(({ checked }) => checked);
          const defaultColumnsUnChecked = columnsData.filter(({ checked }) => !checked);
          return (
            <tbody key={index}>
              <tr>
                {defaultColumnsChecked.map(({ id }) => (
                  <td key={id}>{dataRow[id]}</td>
                ))}
                {isNeedUpdateHeader && customFields?.length > 0
                  ? customFields.map(({ value }, index) => {
                    if (value === true || value === 'true') return <td key={index}><i className="fas fa-check" /></td>;
                    return (<td key={index}>{value}</td>);
                  })
                  : defaultColumnsUnChecked.map(() => (<td />))}
              </tr>
              {noteRow}
            </tbody>
          );
        })}
        <tfoot>
          <tr>
            <td colSpan={columnsData.length}>
              <strong>
                Bokningar: {count}
                <br />
                Avbokade: {cancelledCount}
                <br />
                Summa pris: <FormattedNumber style="currency" currency="SEK" value={sum} />
              </strong>
            </td>
          </tr>
        </tfoot>
      </table>
    );
  }

  renderNoData(bookingList) {
    return bookingList
      ? (
        <div className="alert alert-info">
          <i className="fa fa-info-circle" /> Det finns inga bokningar för vald resurs och period.
        </div>
      ) : null;
  }

  bookingFlagsText(item) {
    if (item.get('askedForPerson')) {
      return flagsText('askedForPerson');
    }
    if (item.get('dropIn')) {
      return flagsText('dropIn');
    }
    return '';
  }
}

BookingList.propTypes = {
  reportsViewState: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const { reportsViewState, resourcesById, locationFeatures } = state;
  const userClientPrefs = getUserClientPreferences(state);

  return {
    columnSettings: bookingColumnsSelector(state),
    hideCancelledBookings: userClientPrefs[reports.hideCancelledBookings],
    hideReservations: userClientPrefs[reports.hideReservations],
    reportsViewState,
    resourcesById,
    routeParams: getRouteParams(state, ownProps),
    enableVehicleBooking: locationFeatures.get('EnableVehicleBooking'),
    enableCompanyBooking: locationFeatures.get('EnableCompanyBooking'),
    reportsQuery: reportsQuerySelector(state, ownProps)
  };
};

export default connect(mapStateToProps)(BookingList);
