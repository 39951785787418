import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import TextEllipsis from '@Components/ui/styled/text-ellipsis';
import { getLocationName } from '@State/selectors';
import MobileUserMenuModal from './mobile-user-menu-modal';

const Img = styled.img`
  margin-right: 10px;
  width: 30;
  height: 30;
`;

const MobileUserMenu = ({ className }) => {
  const [open, setOpen] = useState(false);
  const locationName = useSelector(state => getLocationName(state));
  const username = useSelector(state => state.authState.get('username'));
  const notification = useSelector(state => state.appState.get('notification'));

  useEffect(() => {
    if (notification) {
      setOpen(false);
    }
  }, [notification]);

  const handleClick = (e) => {
    e && e.preventDefault();
    setOpen(!open);
  };

  return (
    <div className={className}>
      <a href="#" onClick={handleClick}>
        <Img className="ui-avatar" phoneMode src="/user.svg" />
        <TextEllipsis width={240}>
          <strong>{locationName}</strong><br />
          <span className="text-muted">{username}</span>
        </TextEllipsis>
        <i className="fa fa-ellipsis-vertical" />
      </a>
      {open && (
        <MobileUserMenuModal onClose={() => setOpen(false)} />
      )}
    </div>
  );
};

export default memo(MobileUserMenu);
