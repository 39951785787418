let touchStartX, touchEndX, touchStartY, touchEndY;
;
;
const getTouchFunctions = (options) => ({
    startTouch: e => {
        touchStartX = e.targetTouches[0].clientX;
        touchStartY = e.targetTouches[0].clientY;
    },
    moveTouch: e => {
        touchEndX = e.targetTouches[0].clientX;
        touchEndY = e.targetTouches[0].clientY;
    },
    endTouch: () => {
        const { onSwipeLeft, onSwipeRight, threshold = 100 } = options;
        if (onSwipeLeft && (touchStartX - touchEndX > threshold)) {
            onSwipeLeft();
        }
        if (onSwipeRight && (touchEndX - touchStartX > threshold)) {
            onSwipeRight();
        }
    }
});
export function initSwipe(options) {
    const touchFunctions = getTouchFunctions(options);
    const { startTouch, moveTouch, endTouch } = touchFunctions;
    document.addEventListener('touchstart', startTouch);
    document.addEventListener('touchmove', moveTouch);
    document.addEventListener('touchend', endTouch);
    return touchFunctions;
}
export function resetSwipe(touchFunctions) {
    const { startTouch, moveTouch, endTouch } = touchFunctions;
    document.removeEventListener('touchstart', startTouch);
    document.removeEventListener('touchmove', moveTouch);
    document.removeEventListener('touchend', endTouch);
}
