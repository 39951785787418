import React, { useState } from 'react';
import Popover from '@Components/ui/popover';
import Button from '@Components/ui/button';
import useIsMounted from '@Hooks/useIsMounted';
import { txt } from '@Utils/i18n-util';
import msg from './popover-delete-button.msg';

const PopoverDeleteButton = ({
  buttonText = txt(msg.btnDelete),
  confirmText = txt(msg.lblConfirmDelete),
  cancelText = txt(msg.btnCancel),
  marginLeft = false,
  gray = false,
  onDelete
}) => {
  const isMounted = useIsMounted();
  const [progress, setProgress] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  const handleConfirm = () => {
    setProgress(true);
    onDelete()
      .catch(() => setProgress(false))
      .finally(() => {
        if (isMounted()) {
          setProgress(false);
          setShowPopover(false);
        }
      });
  };

  const popoverContent = () => (
    <div className="popover-delete">
      <p className="title">{confirmText}</p>
      <div className="flex-item row-button">
        <Button small gray onClick={() => setShowPopover(false)}>
          {cancelText}
        </Button>
        <Button small danger loading={progress} onClick={handleConfirm}>
          {buttonText}
        </Button>
      </div>
    </div>
  );

  return (
    <Popover
      isOpen={showPopover}
      body={popoverContent()}
      onOuterAction={() => setShowPopover(false)}
      preferPlace="column"
    >
      <Button
        small
        gray={gray}
        outlineDanger={!gray}
        marginLeft={marginLeft}
        loading={progress}
        onClick={() => setShowPopover(true)}
      >
        {buttonText}
      </Button>
    </Popover>
  );
};

export default PopoverDeleteButton;
