import React from 'react';
import { useSelector } from 'react-redux';
import { getOrgWideCustomerDb } from '@State/selectors';
import { getCompanyCustomer, getDescription, getPrimaryCustomer, getVehicleCustomer } from '@Utils/booking-util';
import { getTimeByFormat } from '@Utils/time-util';
import {
  getSmsStatus, getChannel, getSmsSent, getSmsType
} from '@Utils/customer-util';
import { PanelWrapper, UpperText } from '@Components/customers/customer-styles';
import { FormGroup } from '@Components/ui/styled/main';
import HistoryButton from '@Components/customers/history-button';
import BookingLabelsPanel from '@Components/customers/customer-detail/bookings/booking-labels-panel';
import { txt } from '@Utils/i18n-util';
import msg from './customer-booking.msg';

const CustomerBooking = (props) => {
  const {
    customerId, resources, description, services, id, createdTime, channel, note,
    locationId, lastUpdateTime, reminders, status, dropIn, askedForPerson
  } = props;
  const orgWideCustomerDb = useSelector(getOrgWideCustomerDb);
  const locationNames = useSelector(state => state.locationNames);

  const customer = getPrimaryCustomer(props);
  const company = getCompanyCustomer(props);
  const vehicle = getVehicleCustomer(props);

  const { bookedAs, name, cancelledTs } = customer || {};
  const { name: orgName, officialIdNo: orgNo } = company || {};
  const { officialIdNo: vehicleRegNo, name: vehicleDescription } = vehicle || {};

  return (
    <PanelWrapper defaultCursor>
      <BookingLabelsPanel
        id={id}
        customerId={customerId}
        status={status}
        dropIn={dropIn}
        askedForPerson={askedForPerson}
      />

      {orgWideCustomerDb && <FormGroup labelText="Plats" text={locationNames.get(locationId)} />}
      {orgNo && <FormGroup labelText={txt(msg.lblOrgNo)} text={orgNo} />}
      {orgName && <FormGroup labelText={txt(msg.lblOrgName)} text={orgName} />}
      {vehicleRegNo && <FormGroup labelText={txt(msg.lblVehicleRegNo)} text={vehicleRegNo} />}
      {vehicleDescription && <FormGroup labelText={txt(msg.lblVehicleDesc)} text={vehicleDescription} />}

      <FormGroup
        labelText={txt(msg.lblResourceName)}
        text={resources.map(r => r.name).join(', ') || '-'}
      />
      {bookedAs && bookedAs !== name && (
        <FormGroup
          labelText={txt(msg.lblBookedAs)}
          text={bookedAs}
        />
      )}
      <FormGroup
        labelText={txt(msg.lblService)}
        text={getDescription(description, services) || '-'}
      />
      <FormGroup
        labelText={txt(msg.lblBookingNo)}
        text={id || '-'}
      />
      <FormGroup
        labelText={txt(msg.lblCreated)}
        text={createdTime ? getTimeByFormat(createdTime, 'LLL') : '-'}
      />
      <FormGroup
        labelText={txt(msg.lblChannel)}
        text={getChannel(channel) || '-'}
      />
      <FormGroup
        labelText={txt(msg.lblUpdated)}
        text={lastUpdateTime ? getTimeByFormat(lastUpdateTime, 'LLL') : '-'}
      />
      {reminders && reminders.filter(({ status }) => getSmsSent(status)).map(({ type, status, statusTs }) => (
        <FormGroup
          key={type}
          labelText={getSmsType(type)}
          text={<UpperText>{`${getSmsStatus(status)} ${getTimeByFormat(statusTs, 'LLL')}`}</UpperText>}
        />
      ))}
      <FormGroup
        labelText={txt(msg.lblCancelled)}
        text={cancelledTs ? getTimeByFormat(cancelledTs, 'LLL') : '-'}
      />
      <FormGroup
        labelText={txt(msg.lblNote)}
        text={note || '-'}
      />
      <HistoryButton white bookingId={id} />
    </PanelWrapper>
  );
};

export default CustomerBooking;
