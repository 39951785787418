import React from 'react';
import { useSelector } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { normalizeOrgNoSE } from '@Utils/normalize';
import TextInput from '@Components/inputs/text-input';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import { companyOrgNoValid, required } from '@Utils/validation';
import SelectField from '@Components/inputs/select-field';
import { txt } from '@Utils/i18n-util';
import msg from './customer-information.msg';

const selector = formValueSelector('customer-company-info');

const CompanyInformationForm = ({
  pristine, submitting, submitSucceeded, handleSubmit, onClose
}) => {
  const officialIdType = useSelector(state => selector(state, 'officialIdType'));
  const validateIdNo = officialIdType === 'SE_ORG';

  return (
    <form autoComplete="off" className="dialog" onSubmit={handleSubmit}>
      <Field
        type="text"
        label={txt(msg.lblCompanyName)}
        name="name"
        component={TextInput}
        validate={required}
      />
      <Field
        type="text"
        label={txt(msg.lblOrgNo)}
        name="officialIdNo"
        component={TextInput}
        normalize={validateIdNo ? normalizeOrgNoSE : null}
        validate={validateIdNo ? [required, companyOrgNoValid] : required}
      />
      <Field
        name="officialIdType"
        component={SelectField}
        validate={required}
      >
        <option value="SE_ORG">Svenskt organisationsnummer</option>
        <option value="">Annan typ av organisationsnummer</option>
      </Field>
      <FormButtonsFooter
        onClose={onClose}
        pristine={pristine}
        submitting={submitting}
        submitSucceeded={submitSucceeded}
        handleSubmit={handleSubmit}
      />
    </form>
  );
};

export default reduxForm({
  form: 'customer-company-info'
})(CompanyInformationForm);
