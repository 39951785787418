import moment from 'moment';
import interact from 'interactjs';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';
import { resourceFromColIdx } from '@Components/calendar/grid/grid-state-helper';
import { AppVersionMultiResource } from '@Utils/embedded-util';
import { getReduxState, postWebkitMessage } from '@Utils/wk-embed-bridges';
import { calcHeightFromMinutes } from '@Utils/time-util';
import { getBookingToCopy } from '@State/clipboard-actions';
import CoordHelper from './grid-coord-helper';

export class ChipDragHandler {
  // NOTE! the 'this' is bound to the chip's 'this'. So all of the eventhandler code essentially executes as if
  // it was part of the chip class. This is to make sure we don't act upon stale state or properties

  constructor(chip) {
    this.chip = chip;
    this.cHelper = new CoordHelper(chip.props);

    this.handlers = {
      dragStart: this.onDragStart.bind(this),
      dragMove: this.onDragMove.bind(this),
      dragEnd: this.onDragEnd.bind(this),
      hold: this.onHold.bind(this),
      move: this.onMove.bind(this),
      tap: this.onTap.bind(this),
      resizeStart: this.onResizeStart.bind(this),
      resizeMove: this.onResizeMove.bind(this),
      resizeEnd: this.onResizeEnd.bind(this),
      touchMove: this.onTouchMove.bind(this),
      mouseEnter: this.onMouseEnter.bind(this),
      mouseLeave: this.onMouseLeave.bind(this)
    };

    this.makeItDraggable();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.cHelper = new CoordHelper(nextProps);
  }

  get coordsHelper() {
    return this.cHelper;
  }

  get state() {
    return this.chip.state;
  }

  get props() {
    return this.chip.props;
  }

  setState(state) {
    this.chip.setState(state);
  }

  dispose() {
    if (this.interactable) {
      this.interactable.unset();
    }
  }

  makeItDraggable() {
    const manualStart = interact.supportsTouch() && (window.navigator.userAgent.indexOf('Windows NT 10') == -1);
    const chipEl = ReactDOM.findDOMNode(this.chip);
    const evOwEl = document.getElementById('eventOwner');
    const scrollEl = document.getElementById('gridcontainer');

    this.interactable = interact(chipEl)
      .pointerEvents({
        holdDuration: 150
      })
      .draggable({
        manualStart,
        inertia: false,
        autoScroll: {
          container: scrollEl,
          margin: 25,
          distance: 1,
          interval: 1
        },
        restrict: {
          endOnly: false,
          restriction: evOwEl, // Restrict movement to the bounds of the eventOwner node
          elementRect: {
            top: 0, left: 0, bottom: 1, right: 1
          } // The chip element's rectangle
        }
      })
      .styleCursor(false)
      .preventDefault('never') // scroll on touch, try with 'auto'
      .origin(evOwEl)
      .on('click', (ev) => {
        if (this.interacting) {
          ev.stopImmediatePropagation();
          ev.preventDefault();
        } else {
          this.handlers.tap(ev);
        }
      })
      .on('hold', this.handlers.hold)
      .on('move', this.handlers.move)
    // .on('tap', this.handlers.tap) /* dont use tap, as it will still trigger a click event, which is causing loads of other problems */
      .on('dragstart', this.handlers.dragStart)
      .on('dragmove', this.handlers.dragMove)
      .on('dragend', this.handlers.dragEnd)
      .on('touchmove', this.onTouchMove.bind(this))
      .resizable({
        allowFrom: '.resize-handle',
        manualStart,
        edges: {
          top: false,
          left: false,
          bottom: '.resize-handle',
          right: false
        },
        inertia: false,
        autoScroll: {
          container: scrollEl,
          margin: 50,
          distance: 1,
          interval: 1
        },
        restrict: {
          endOnly: false,
          restriction: evOwEl
        }
      })
      .on('resizestart', this.handlers.resizeStart)
      .on('resizemove', this.handlers.resizeMove)
      .on('resizeend', this.handlers.resizeEnd)
      .on('mouseenter', this.handlers.mouseEnter)
      .on('mouseleave', this.handlers.mouseLeave);
  }

  onTouchMove(event) {
    if (this.interacting) {
      event.preventDefault();
    }
  }

  onMouseEnter(event) {
    const interacting = this.interacting || window.interHack;
    const { isMultiResourceView, bkfBkId } = this.props;
    if (isMultiResourceView && !bkfBkId && !interacting) {
      // This value is read from chip hover tracker component
      window.chipHoverBookingId = this.props.id;
    }
  }

  onMouseLeave(event) {
    const { isMultiResourceView, bkfBkId, confirmMoveEnabled } = this.props;
    if (isMultiResourceView && !bkfBkId && !this.interacting) {
      const { showConfirm } = confirmMoveEnabled
        ? this.chip.confirmState()
        : { showConfirm: false };

      if (!showConfirm) {
        // This value is read from chip hover tracker component
        window.chipHoverBookingId = null;
      }
    }
  }

  onDragStart(event) {
    event.stopPropagation(); // prevent grid event
    ReactTooltip.hide();

    try {
      const ch = this.coordsHelper;
      const newX = ch.withXMargin(ch.snapToGridX(event));
      const leftPct = ch.leftPct(newX);
      const widthPct = ch.widthPct(ch.draggingWidth);
      const chipEl = ReactDOM.findDOMNode(this.chip);
      const diff = event.clientY - chipEl.offsetTop;

      this.setState({
        sourceColIdx: this.state.coords.colIdx,
        coords: {
          ...this.state.coords,
          leftPct,
          widthPct
        },
        dragChipTopDiff: diff,
        isDragging: true
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  }

  onDragMove(event) {
    this.interacting = true;
    window.interHack = true;
    event.stopPropagation(); // prevent grid event

    try {
      const diff = this.state.dragChipTopDiff;
      const ch = this.coordsHelper;
      const newColIdx = ch.columnIdx(event);
      const newY = ch.withYMargin(ch.snapToGridY(event.clientY - diff));
      const newX = ch.withXMargin(ch.snapToGridX(event));
      const leftPct = ch.leftPct(newX);
      const newStartTime = ch.timeFor(newX, newY);
      const newEndTime = moment(newStartTime).add(this.chip.duration(), 'ms');

      this.setState({
        coords: {
          ...this.state.coords,
          top: newY,
          leftPct,
          colIdx: newColIdx
        },
        startTime: newStartTime,
        endTime: newEndTime
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  }

  onDragEnd(event, newBooking) {
    /* Delaying the reset of interacting, so that the click event that occurs after releasing, dont trigger a new "tap/click" outside the dragged area */
    setTimeout(() => {
      window.interHack = false;
      this.interacting = false;
    }, 100);
    // this.interacting = false;
    event.stopPropagation(); // prevent grid event

    this.setState({
      dragChipTopDiff: 0,
      isDragging: false
    });
    if (!this.props.confirmMoveEnabled && !this.props.showForm) {
      this.onDragEndConfirm();
    } else if (this.props.embeddedInApp) {
      this.handleMoveEmbedded(newBooking);
    }
  }

  onDragEndConfirm = (confirmOptions) => {
    try {
      this.props.onChipMove({
        id: this.props.id,
        sourceColIdx: this.state.sourceColIdx,
        startTime: this.state.startTime,
        endTime: this.state.endTime,
        colIdx: this.state.coords.colIdx,
        ...confirmOptions
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  };

  onDragEndCancel = () => {
    this.setState({
      isDragging: false,
      isResizing: false
    });
  };

  onHold(event) {
    event.stopPropagation(); // prevent grid event

    try {
      const { interaction } = event;
      if (!interaction.interacting()) {
        const resizeClass = 'resize-handle';
        const { target } = event;
        const action = (target.classList.contains(resizeClass) || target.parentElement.classList.contains(resizeClass)) ? 'resize' : 'drag';

        interaction.start({ name: action },
          event.interactable,
          event.currentTarget);
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  }

  onMove(event) {
    if (event.interaction.interacting()) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onTap(event) {
    event.preventDefault(); // prevent double tap
    event.stopPropagation(); // prevent grid event
    ReactTooltip.hide();

    if (this.props.isMultiResourceView) {
      window.chipHoverBookingId = this.props.id;
      this.props.updateHighlightedBookings();
    }

    try {
      this.chip.handleChipClick({
        chipId: this.props.id,
        chipEl: (event.currentTarget)
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  }

  onResizeStart(event) {
    ReactTooltip.hide();

    try {
      const chipEl = ReactDOM.findDOMNode(this.chip);
      const ch = this.coordsHelper;
      const newY = ch.snapToGridY(event);

      this.setState({
        sourceColIdx: this.state.coords.colIdx,
        isResizing: true,
        orirginalChipTop: newY,
        resizeChipOriginalHeight: chipEl.offsetHeight
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  }

  onResizeMove(event) {
    this.interacting = true;
    window.interHack = true; /* this is to prevent grid marker from triggering a click event when the resize move ends, and the pointer is outside the resized chip */

    try {
      const ch = this.coordsHelper;
      const dh = (this.state.resizeChipOriginalHeight + event.dy);
      const x = this.state.coords.colIdx * ch.columnWidth;
      const afterTime = this.props.afterTime || 0;
      const atHeight = calcHeightFromMinutes(this.props.gridProps, afterTime, false);

      let newH = ch.snapToGridY(dh);
      if (newH < ch.rowHeight + atHeight) {
        newH = ch.rowHeight + atHeight;
      }
      const newEndTime = ch.timeFor(x, newH + this.state.coords.top);

      this.setState({
        coords: { ...this.state.coords, height: ch.withoutMargin(newH) },
        resizeChipOriginalHeight: dh,
        endTime: newEndTime
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  }

  onResizeEnd(event) {
    event.stopPropagation(); // prevent grid event
    setTimeout(() => {
      window.interHack = false;
      this.interacting = false;
    }, 200);
    // this.interacting = false;
    this.setState({
      isResizing: false,
      resizeChipOriginalHeight: 0
    });

    if (!this.props.confirmMoveEnabled && !this.props.showForm) {
      this.onResizeEndConfirm();
    } else if (this.props.embeddedInApp) {
      this.handleResizeEmbedded();
    }
  }

  onResizeEndConfirm = () => {
    this.onDragEndCancel();

    try {
      this.props.onChipResize({
        id: this.props.id,
        sourceColIdx: this.state.sourceColIdx,
        startTime: this.state.startTime,
        endTime: this.state.endTime,
        colIdx: this.state.coords.colIdx
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  };

  getMoveEvent = (newBooking) => {
    const {
      id, routeParams, resources, resourceId, startTime, endTime
    } = this.props;
    const { colIdx, sourceColIdx } = this.state.coords;
    const state = getReduxState();
    const duration = this.state.endTime.diff(this.state.startTime, 'minutes');
    const initDuration = endTime.diff(startTime, 'minutes');

    const sourceResourceId = resourceFromColIdx(state, routeParams, sourceColIdx)?.id || resourceId;
    const targetResourceId = resourceFromColIdx(state, routeParams, colIdx)?.id || resourceId;

    const initState = {
      resources,
      resourceId,
      startTime,
      endTime,
      duration: initDuration
    };

    const bookingId = (newBooking && id === 'DRAGGER') ? newBooking.id : id;

    return {
      bookingId,
      startTime: this.state.startTime,
      endTime: this.state.endTime,
      duration,
      sourceResourceId,
      targetResourceId,
      resources,
      initState,
      booking: newBooking ? getBookingToCopy(newBooking) : null
    };
  };

  handleResizeEmbedded = () => {
    // Temporary fix for broken confirm in app
    if (this.props.embedVersion === AppVersionMultiResource) {
      this.onResizeEndConfirm();
      return;
    }

    postWebkitMessage('confirmResize', this.getMoveEvent());
  };

  handleMoveEmbedded = (newBooking) => {
    // Temporary fix for broken confirm in app
    if (this.props.embedVersion === AppVersionMultiResource) {
      this.onDragEndConfirm();
      return;
    }

    const moveEvent = this.getMoveEvent(newBooking);
    const {
      customerEmail, customerPhoneNumber, copyOnPaste, viaClipboard
    } = this.props;

    const message = {
      ...moveEvent,
      customerEmail,
      customerPhoneNumber,
      viaClipboard
    };

    postWebkitMessage(copyOnPaste ? 'confirmCopy' : 'confirmMove', message);
  };
}
