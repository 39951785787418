import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { required, number, minValue } from '@Utils/validation';
import CurrencyUtil from '@Utils/currency-util';
import LabelTooltip from '@Components/ui/styled/label-tooltip';
import CheckBox from '@Components/inputs/checkbox';
import { Panel } from '../ui/styled/main';
import TextInput from '../inputs/text-input';
import MultiVatForm from './multi-vat-form';

const ServiceFormPrice = ({ showVat, vatRates, multiVat, priceLabel, useDynamicPricing }) => (
  <>
    <label className="control-label mt2">Inställningar för pris</label>
    {multiVat ? (
      <Panel>
        <FieldArray name="multiVatRows" component={MultiVatForm} />
      </Panel>
    ) : (
      <Panel>
        <div className="horizontal-scroll-container">
          <table className="table-form">
            <tbody>
              <tr>
                <td className="width-lg">
                  <Field
                    name="price"
                    component={TextInput}
                    label={priceLabel}
                    unitLabel={CurrencyUtil.currencySymbol()}
                    validate={[number, minValue(0)]}
                  />
                </td>
                <td className="width-lg">
                  <Field
                    name="priceEvening"
                    component={TextInput}
                    label="Pris kväll"
                    disabled={!useDynamicPricing}
                    unitLabel={CurrencyUtil.currencySymbol()}
                    validate={[number, minValue(0)]}
                  />
                </td>
                <td className="width-lg">
                  <Field
                    name="priceHoliday"
                    component={TextInput}
                    label="Pris helg"
                    disabled={!useDynamicPricing}
                    unitLabel={CurrencyUtil.currencySymbol()}
                    validate={[number, minValue(0)]}
                  />
                </td>
                {showVat && (
                  <td className="width-md">
                    <div className="form-group">
                      <label htmlFor="vatPct" className="control-label">Moms</label>
                      <div className="select-container">
                        <Field
                          id="vatPct"
                          name="vatPct"
                          component="select"
                          className="form-control"
                          validate={[required]}
                        >
                          {vatRates.map(vat => <option key={vat} value={vat}>{`${vat}%`}</option>)}
                        </Field>
                      </div>
                    </div>
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="form-group form-horizontal">
          <Field
            name="useDynamicPricing"
            component={CheckBox}
            label={(
              <>
                Använd kvälls- och helgpriser
                <LabelTooltip id="price-info" text="När kvällspriser startar och vilka dagar som räknas som helgdagar ställs in under Onlinebokning > Inställningar." />
              </>
            )}
          />
          <Field name="priceFrom" component={CheckBox} label="Visa som från-pris" />
        </div>
      </Panel>
    )}
  </>
);

export default ServiceFormPrice;
