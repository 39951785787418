import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '@Components/ui/select/select';
import MobileNav from '../../nav/mobile-nav';

const CalendarHeaderResourceTablet = ({ calendarTitle, routeParams }) => {
  const [selectedValue, setSelectedValue] = useState(calendarTitle);
  useEffect(() => {
    if (calendarTitle) setSelectedValue(calendarTitle);
  }, [calendarTitle]);
  return (
    <div className="resource-selector-tablet">
      <Select value={selectedValue}>
        <MobileNav routeParams={routeParams} isiPadMode />
      </Select>
    </div>
  );
};

CalendarHeaderResourceTablet.propTypes = {
  calendarTitle: PropTypes.string,
  routeParams: PropTypes.object
};

export default CalendarHeaderResourceTablet;
