import Pusher from 'pusher-js';
import { customerUpdated } from '@State/customer-actions';
import { bookingAdded, bookingChanged, bookingConfirmed, bookingCancelled, bookingRefunded, bookingDeleted, bookingMoved, fetchBooking, bookingStatusChanged, bookingAttributeChanged, customFieldsChanged } from '@State/booking-actions';
import { bookingCustomerAdded, bookingCustomerRemoved } from '@State/bkf/actions';
import { configChanged } from '@State/account-actions';
import { posUnitClosed, printerQueued, printerProgress, printerDone, printerStatus, posUnitOpenedOther } from '@State/pos-config-actions';
import { invoiceStatusUpdated } from '@State/invoice-actions';
import { paymentProgress, paymentResult } from '@State/pos-actions';
import { CANCEL_BOOKING, CHANGE_BOOKING_ATTRIBUTES, CHANGE_BOOKING_DETAILS, CHANGE_BOOKING_STATUS, CHANGE_PREFS, CLOSE_VUNIT, CONFIRM_BOOKING, CREATE_BOOKING, DELETE_BOOKING, DELETE_UNCONFIRMED_BOOKING, INVOICE_STATUS, MODIFY_CUSTOMER, MOVE_BOOKING, OPEN_VUNIT, PAYMENT_PROGRESS, PAYMENT_RESULT, PRINTER_DONE, PRINTER_PROGRESS, PRINTER_QUEUED, PRINTER_STATUS, REFUND_BOOKING, SWITCH_VUNIT_DEVICE_TOKEN, ADD_BOOKING_CUSTOMER, DELETE_BOOKING_CUSTOMER, CHANGE_BOOKING_CUSTOM_FIELDS } from './pusher-messages';
import { pusherKey, pusherCluster } from './config';
import { postWebkitMessage } from './wk-embed-bridges';
import { bookingTypes, isBookingInView } from './booking-util';
// Set to true to enable pusher logging
Pusher.logToConsole = true;
function getEntityId({ entityId }) {
    return /^\d+$/.test(entityId) ? parseInt(entityId) : entityId;
}
function onError(error) {
    console.error(error);
    window.Sentry.captureException(error);
}
function fetchBookingData(event) {
    return (dispatch, getState) => {
        try {
            if (isBookingInView(getState(), event.data)) {
                return dispatch(fetchBooking(getEntityId(event)));
            }
            else {
                console.info('Skipping fetching booking data for event', event);
            }
        }
        catch (error) {
            console.error('Failed to fetch booking data for event', event, error);
        }
        return Promise.resolve(null);
    };
}
export function initializePusher(dispatch, org, loc) {
    // NOTE! This is to ensure we disconnect when switching org&loc without reloading the webpage.
    //
    if (window.pusher) {
        window.pusher.disconnect();
    }
    const pusher = (window.pusher = new Pusher(pusherKey(), {
        cluster: pusherCluster()
    }));
    pusher.connection.bind('connected', () => {
        if (window.pusherUnavailableSince) {
            const disconnectedTime = (new Date().valueOf() - window.pusherUnavailableSince.valueOf()) / 1000;
            console.info(`Pusher reconnected after being unavailable or failed since ${window.pusherUnavailableSince}, for ${disconnectedTime} seconds`);
        }
        window.pusherUnavailableSince = null;
        window.pusherSocketId = pusher.connection.socket_id;
        postWebkitMessage('pusherAvailable', { pusherSocketId: window.pusherSocketId });
        console.info(`Pusher socket ID assigned, id: ${window.pusherSocketId}`);
    });
    pusher.connection.bind('failed', () => {
        window.pusherUnavailableSince = new Date();
        onError('Pusher connection failed / This implies that WebSockets are not natively available and an HTTP-based transport could not be found.');
    });
    pusher.connection.bind('unavailable', () => {
        window.pusherUnavailableSince = new Date();
        onError('Pusher connection unavailable / The connection is temporarily unavailable. In most cases this means that there is no internet connection. It could also mean that Pusher is down, or some intermediary is blocking the connection. In this state, Pusher will automatically retry the connection every ten seconds. connecting_in events will still be triggered.');
    });
    pusher.connection.bind('error', err => {
        if (err?.error?.data?.code === 4004) {
            onError('Pusher connection limit reached');
        }
    });
    const channel = pusher.subscribe(`${org}_${loc}`);
    channel.bind(CREATE_BOOKING, async (event) => {
        const booking = await dispatch(fetchBookingData(event));
        if (booking) {
            dispatch(bookingAdded(booking));
        }
    });
    channel.bind(DELETE_BOOKING, (event) => {
        dispatch(bookingDeleted(getEntityId(event)));
    });
    channel.bind(DELETE_UNCONFIRMED_BOOKING, (event) => {
        const { bookingType, customerId } = event.data;
        if (bookingType === bookingTypes.ClassBooking) {
            dispatch(bookingCustomerRemoved(getEntityId(event), customerId));
        }
        else {
            dispatch(bookingDeleted(getEntityId(event)));
        }
    });
    channel.bind(CANCEL_BOOKING, (event) => {
        const { customerIds, cancelledChannel } = event.data;
        dispatch(bookingCancelled(getEntityId(event), customerIds, cancelledChannel));
    });
    channel.bind(REFUND_BOOKING, (event) => {
        dispatch(bookingRefunded(getEntityId(event), event.data.refundResult));
    });
    channel.bind(MOVE_BOOKING, (event) => {
        const moveEvent = {
            id: getEntityId(event),
            ...event.data
        };
        dispatch(bookingMoved(moveEvent));
    });
    channel.bind(CONFIRM_BOOKING, async (event) => {
        const booking = await dispatch(fetchBookingData(event));
        if (booking) {
            const { customerId } = event.data;
            const customer = booking.customers.find(c => c.customerId === customerId);
            dispatch(bookingConfirmed(getEntityId(event), booking, customer));
        }
    });
    channel.bind(CHANGE_BOOKING_DETAILS, (event) => {
        dispatch(bookingChanged(getEntityId(event), event.data));
    });
    channel.bind(CHANGE_BOOKING_STATUS, (event) => {
        const statusEvent = {
            bookingId: getEntityId(event),
            ...event.data
        };
        dispatch(bookingStatusChanged(statusEvent));
    });
    channel.bind(CHANGE_BOOKING_ATTRIBUTES, (event) => {
        const attributesEvent = {
            bookingId: getEntityId(event),
            ...event.data
        };
        dispatch(bookingAttributeChanged(attributesEvent));
    });
    channel.bind(CHANGE_BOOKING_CUSTOM_FIELDS, (event) => {
        const customFieldsEvent = {
            bookingId: getEntityId(event),
            ...event.data
        };
        dispatch(customFieldsChanged(customFieldsEvent));
    });
    channel.bind(ADD_BOOKING_CUSTOMER, (event) => {
        const { customer } = event.data;
        dispatch(bookingCustomerAdded(getEntityId(event), customer));
    });
    channel.bind(DELETE_BOOKING_CUSTOMER, (event) => {
        const { customerId } = event.data;
        dispatch(bookingCustomerRemoved(getEntityId(event), customerId));
    });
    channel.bind(MODIFY_CUSTOMER, (event) => {
        dispatch(customerUpdated(getEntityId(event), event.data.customer));
    });
    channel.bind(CHANGE_PREFS, (event) => {
        dispatch(configChanged(event.data.prefs));
    });
    channel.bind(PAYMENT_PROGRESS, (event) => {
        dispatch(paymentProgress(getEntityId(event), event.data));
    });
    channel.bind(PAYMENT_RESULT, (event) => {
        dispatch(paymentResult(getEntityId(event), event.data));
    });
    channel.bind(PRINTER_QUEUED, (event) => {
        dispatch(printerQueued(getEntityId(event)));
    });
    channel.bind(PRINTER_PROGRESS, (event) => {
        dispatch(printerProgress(getEntityId(event), event.data.status));
    });
    channel.bind(PRINTER_DONE, (event) => {
        dispatch(printerDone(getEntityId(event), event.data.status));
    });
    channel.bind(PRINTER_STATUS, (event) => {
        dispatch(printerStatus(getEntityId(event), event.data.status));
    });
    channel.bind(OPEN_VUNIT, (event) => {
        dispatch(posUnitOpenedOther(getEntityId(event), event.data.userAgentFields));
    });
    channel.bind(SWITCH_VUNIT_DEVICE_TOKEN, (event) => {
        dispatch(posUnitOpenedOther(getEntityId(event), event.data.userAgentFields));
    });
    channel.bind(CLOSE_VUNIT, (event) => {
        dispatch(posUnitClosed(getEntityId(event)));
    });
    channel.bind(INVOICE_STATUS, (event) => {
        dispatch(invoiceStatusUpdated(getEntityId(event), event.data.status));
    });
}
