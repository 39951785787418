import styled, { css } from 'styled-components';
import {
  colors, fsw, fs
} from '@Components/ui/styled/variables';
import { CircleStyle, Flex } from '@Components/ui/styled/main';

export const styleBlockTotalSale = { marginBottom: '18px' };

export const PanelWrapper = styled.div`
  margin-top: 30px;
`;

export const ReportTitle = styled.div`
  display: flex;
  flex-shrink: 0;
`;

export const ReportTitleButtons = styled.div`
  margin-left: auto;
  white-space: nowrap;
  display: flex;
  align-items: flex-start;
`;

const ReportText = styled.div`
  color: #000;
`;

export const FlexOne = styled.div`
  flex: 1;
  margin-right: ${p => p.marginRight || '0'};
`;

export const ReportTableRow = styled(ReportText)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 33.3%);
  margin-bottom: 5px;
  div:first-child {
    min-width: 250px;
    margin-left: 0;
  }
  div {
    margin-left: auto;
    &.left {
      margin-right: auto;
      margin-left: 0;
    }
  }
  &.border-t {
    border-top: 1px solid ${colors.softGray60};
    padding-top: 4px;
  }
  &.border-b {
    border-bottom: 1px solid ${colors.softGray60};
  }

  ${props => props.bold && css`
    font-weight: ${fsw.medium};
  `}

  ${props => props.size && css`
    grid-template-columns: repeat(auto-fill, ${props.size});
  `}
`;

export const ReportTableRowHeader = styled(ReportTableRow)`
  font-weight: ${fsw.medium};
  border-bottom: 1px solid ${colors.softGray60};
  padding-bottom: 5px;
`;

export const ReportTextLabel = styled.div`
  span {
    color: ${colors.textMuted};
  }
  p {
    font-size: 17px;
    font-weight: ${fsw.medium};
    color: #000;
  }
  .lastLabel {
    margin-bottom: 30px;
    margin-top: 58px;
  }
`;

export const RefundStyle = styled.div`
  margin-top: 64px;
`;

export const RefundItemWrap = styled.div`
  margin-bottom: 20px;
  height: 45px;
  border-radius: 10px;
  border: solid 1px #d81818;
  color: #cf3939;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  cursor: pointer;
  div {
    width: 100%;
    flex: 1;
    display: flex;
  }
  i {
    font-size: 20px;
  }
`;

export const CcuFooter = styled.div`
  margin: 32px 0;
  text-align: center;
  color: ${colors.textMuted};
`;

export const RowItem = styled.div`
  margin-bottom: 10px;
`;

export const SummaWrap = styled.div`
  border-top: 1px solid #eaedf1;
  padding-top: 10px;
  margin-top: 5px;
  display: flex;
  width: 100%;
`;

export const buttonStyle = {
  width: '50px',
  height: '30px'
};

export const marginButtonStyle = {
  ...buttonStyle,
  margin: '0 10px'
};

export const wrapperStyle = {
  margin: 'auto 0px auto 16px'
};

export const labelStyle = {
  width: 'fit-content'
};

export const iconStyle = {
};

export const Wrapper = styled.div`
  padding: 25px;
`;

export const IconWrapper = styled.span`
  display: inline-block;
  margin-top: -2px;
  margin-right: 10px;
  font-size: 22px;
  vertical-align: middle;
`;

export const BlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-align: center;
`;

export const SaleItemBlock = styled.div`
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  background: ${colors.softGray40};
  border-radius: 6px;
  margin-top: 10px;
  padding: 6px 6px 6px 10px;
  border-left: 5px solid ${props => props.color ? props.color : colors.softGray40};
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  font-weight: 500;
  line-height: 20px;

  p {
    margin: 0;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div {
    button {
      padding: 0;
      width: 35px;
      height: 30px;
      background: #fff;
      color: ${colors.primaryDark};
      outline: none;
      border-radius:4px;
      font-weight: 600;
      margin-left: 16px;
      &:active {
        background: ${colors.primaryDark};
        color: #fff;
      }
      &:disabled {
        cursor: default;
        opacity: 0.65;
      }
    }
  }
`;
export const SaleItemQuantity = styled.div`
  width: 25px;
  padding: 5px 0;
`;
export const SaleItemContent = styled.div`
  flex: 1;
  min-width: 0;
  padding: 5px 0;
`;
export const SaleItemContentRow = styled.div`
  display: flex;
  column-gap: 10px;
  justify-content: space-between;
  opacity: ${props => props.subItem ? 0.5 : 1};
`;
export const SaleItemStockWarning = styled.div`
  font-size: 12px;
  margin-top: 2px;
  color: ${colors.textMuted};

  i {
    color: ${colors.warning};
    vertical-align: middle;
    margin: 0 5px 2px 0;
  }
`;

export const PosSaleRow = styled.div`
  padding: 8px 15px;
  display: flex;
  justify-content: space-between;
  cursor: default;

  p {
    margin: 0;
    font-weight: ${p => p.bold ? 600 : 400};
  }
  .fa {
    color: ${colors.darkGray30};
    font-size: 12px;
    margin-left: 8px;
    cursor: pointer;
  }
`;

export const PosSaleTransactionRow = styled(PosSaleRow)`
  padding: 0 15px 6px 0;
  font-size: ${fs.xs};
  color: ${colors.darkGray80};
  p {
    &:first-child {
      padding-left: 15px;
    }
    margin: 0;
  }
  i, img {
    margin-right: 7px;
  }
  img {
    height: 14px !important;
    width: 14px !important;
    margin-left: 1px;
  }
`;

export const PosSaleRowTotal = styled(PosSaleRow)`
  border-top: 1px solid rgba(0, 0, 0, 0.05);

  p {
    font-size: 17px;
    font-weight: 700;
  }
`;

export const HeaderLock = styled.div`
  background-color: var(--color-soft-gray-40);
`;

export const HeaderLockUser = styled.div`
  margin: 8px 10px 0 0;
`;

export const TitleEmptyDataStyle = styled.p`
  padding-top: 50px;
  text-align: center;
  color: ${colors.textMuted};
`;

export const Circle = styled(CircleStyle)`
  margin-right: 0;
  margin-left: 6px;
`;

export const BlockStyledPos = styled(BlockStyled)`
  position: relative;
  top: -47px;
`;
export const BlockGrid = styled.div`
  width: ${p => `${p.columns * 340}px`};
  column-count: ${p => p.columns};
  column-width: 320px;
  column-gap: 0;
`;
export const BlockButton = styled.div`
  display: inline-block;
  border-radius: 8px;
  width: 320px;
  font-weight: 400;
  padding: 18px 18px 18px 18px;
  background: #fff;
  border: none;
  margin-bottom: 20px;
  text-align: left;
  cursor: pointer;

  &:focus {
    box-shadow: none;
  }
  &:active {
    color: ${colors.darkGray80};
    background: ${colors.softGray20};
  }

  h4 {
    margin-top: 0;
  }

  h5 {
    margin: 0;

    &:only-child {
      text-align: center;
    }
  }
`;

export const PosInfoBlock = styled.div`
  padding: 20px 20px 30px 20px;
  color: ${colors.textMuted};
  text-align: left;
  max-width: 400px;

  strong {
    font-size: 15px;
    color: ${colors.darkGray80};
  }
  p {
    margin-bottom: 0;
  }
`;

export const FlexFull = styled(Flex)`
  width: 100%;
`;

export const Status = styled.span`
  margin-left: 10px;
  margin-top: -2px;
`;
