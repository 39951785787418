import React from 'react';
import { useSelector } from 'react-redux';
import { logMeOut } from '@Utils/account';
import { isWrappedInApp } from '@Utils/embedded-util';
import { postWebkitMessage } from '@Utils/wk-embed-bridges';
import ModalDialog from '@Components/dialogs/modal-dialog';

const MobileUserMenuModal = ({ onClose }) => {
  const wrappedInApp = useSelector(isWrappedInApp);
  const locationOptions = useSelector(state => state.locationOptions);

  const showNotificationSettings = (ev) => {
    ev.preventDefault();
    postWebkitMessage('showNotificationSettings');
  };

  const showSwitchAccount = (ev) => {
    ev.preventDefault();
    postWebkitMessage('showSwitchAccount');
  };

  const handleLogout = () => {
    if (wrappedInApp) {
      logMeOut(true);
      postWebkitMessage('logMeOut');
    } else {
      logMeOut();
    }
  };

  return (
    <ModalDialog onClose={onClose} contentSize="medium" zIndex={20000}>
      <div className="mobile-user-menu-buttons">
        <button className="btn-option" onClick={showNotificationSettings}>Notiser</button>
        {locationOptions.size > 1 && (
          <button className="btn-option" onClick={showSwitchAccount}>Byt konto</button>
        )}
        <button className="btn-option btn-danger" onClick={handleLogout}>Logga ut</button>
        <button className="btn-option" onClick={onClose}>Stäng</button>
      </div>
    </ModalDialog>
  );
};

export default MobileUserMenuModal;
