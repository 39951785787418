import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import useKeyPress from '@Hooks/useKeyPress';

import { txt } from '@Utils/i18n-util';
import Modal from '@Components/ui/modal';
import { colors } from '@Components/ui/styled/variables';
import {
  BtnBack,
  DialogButtonsVertical,
  DialogButton,
  DialogTitle,
  DialogTitleBtn,
  DialogTitleRow,
  DialogCloseX
} from '@Components/dialogs/dialog-styles';
import msg from './dialog.msg';

export const ModalDialogContainer = styled.div`
  position: relative;
  z-index: 10001;
  display: flex;
  overflow: inherit;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  form {
    padding: 0 !important;
  }

  ${p => p.phoneMode && css`
    display: block;
  `}
`;

function getDialogWidth({ size }) {
  switch (size) {
    case 'full':
      return '1200px';
    case 'xxl':
      return '800px';
    case 'xl':
      return '600px';
    case 'large':
      return '500px';
    case 'medium':
      return '400px';
    case 'small':
      return '350px';
    default:
      return '320px';
  }
}

export const ModalDialogContent = styled.div`
  position: relative;
  width: ${getDialogWidth};
  opacity: 1;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: ${props => props.largePadding ? '24px' : '16px'};
  height: fit-content;
  min-height: 150px;
  margin: 20px;
  ${p => p.size === 'xl' && css`
    .sale-gray-bg {
      margin: -18px -16px -14px 16px;
      padding: 18px;
      top: 2px;
      position: relative;
      border-radius: 0 8px 8px 0;
      background: ${colors.softGray40};
    }
  `};

  ${p => p.phoneMode && css`
    margin: 0;
    width: 100%;
    max-height: calc(100vh - 60px);
    border-radius: 16px 16px 0 0;
    position: fixed;
    bottom: 0;
    left: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  `}
`;

const ModalDialog = (props) => {
  const ref = useRef();
  const {
    title,
    onBack,
    onClose,
    onEnter,
    buttons = [],
    children,
    className,
    closeButtonText,
    closeButtonX = false,
    closeOnClickOutside = true,
    hideCloseButton = false,
    leftBtn = false,
    contentSize,
    alignTop = false,
    focusTrapPaused = false,
    largePadding,
    zIndex
  } = props;

  const phoneMode = useSelector(state => state.mainViewState.get('phoneMode'));

  useKeyPress(13, () => onEnter && onEnter(), [onEnter]);

  return (
    <Modal
      focusTrapPaused={focusTrapPaused}
      underlayClickExits={closeOnClickOutside}
      titleText={title || ' '}
      onExit={onClose}
      verticallyCenter={contentSize !== 'full' && !alignTop}
      underlayStyle={zIndex ? { zIndex } : undefined}
    >
      <ModalDialogContainer phoneMode={phoneMode}>
        <ModalDialogContent
          ref={ref}
          className={className}
          size={contentSize}
          largePadding={largePadding}
          phoneMode={phoneMode}
        >
          {title && (
            <DialogTitle large={largePadding}>
              {onBack && <BtnBack onClick={onBack}><i className="fas fa-chevron-left" /></BtnBack>}
              <DialogTitleRow center={hideCloseButton || closeButtonX} leftBtn={leftBtn || onBack || hideCloseButton}>{title}</DialogTitleRow>
              {!hideCloseButton && !closeButtonX && (
                <DialogTitleBtn tabIndex={0} onClick={onClose}>{closeButtonText || txt(msg.btnCancel)}</DialogTitleBtn>
              )}
              {closeButtonX && (
                <DialogCloseX tabIndex={0} onClick={onClose}><i className="fa fa-times" /></DialogCloseX>
              )}
            </DialogTitle>
          )}
          {children}
          {buttons.length > 0 && (
            <DialogButtonsVertical>
              {buttons.filter(b => b).map(
                ({ name, onClick, ...props }, index) => (
                  <DialogButton
                    {...props}
                    key={index}
                    onClick={onClick}
                  >
                    {name}
                  </DialogButton>
                )
              )}
            </DialogButtonsVertical>
          )}
        </ModalDialogContent>
      </ModalDialogContainer>
    </Modal>
  );
};

ModalDialog.propTypes = {
  title: PropTypes.string,
  buttons: PropTypes.array
};

export default ModalDialog;
