import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { initSwipe, resetSwipe } from '@Utils/swipe';
import { getMainViewStyle } from '@Utils/embedded-util';
import { navigate, getGroupUrl, getResourceUrl, getSectionUrl } from '@Utils/navigate';
import { toggleGridScrollability } from '@State/view-actions';
import { getCalendarTitle } from '@State/selectors';
import MobileUserMenu from '@Components/nav/mobile-user-menu';
import { classes } from '../ui/utils';
import ResourceSelector from '../calendar/resource-selector/resource-selector';
import MobileNavItem from '../calendar/resource-selector/mobile-nav-item';

class MobileNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.routeParams, this.props.routeParams) && this.state.open) {
      setTimeout(() => this.hideSelector(), 100);
    }
  }

  showSelector = (ev) => {
    ev.preventDefault();
    this.props.toggleScrollability(false);
    this.setState({ open: true });
    this.swipeEvents = initSwipe({
      onSwipeLeft: this.hideSelector
    });
  };

  hideSelector = (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    this.props.toggleScrollability(true);
    this.setState({ open: false });
    resetSwipe(this.swipeEvents);
  };

  render() {
    const { open } = this.state;
    const { dateLabel, isiPadMode, routeParams, mainViewStyle } = this.props;
    const classListHide = classes({
      'mobile-nav-backdrop': true,
      show: open
    });
    const classList = classes({
      'mobile-nav-container': true,
      show: open
    });

    // show list of resources for dropdown
    if (isiPadMode) {
      return this.resourceSelector();
    }
    return (
      <>
        <div className={classListHide} role="presentation" onClick={this.hideSelector} />
        <div className={classList} style={mainViewStyle}>
          <div className="mobile-nav-header">
            <MobileUserMenu routeParams={routeParams} />
          </div>
          <div className="mobile-nav-list">
            <ul>
              <MobileNavItem
                routeParams={routeParams}
                icon="far fa-address-book"
                label="Kundregister"
                href={getSectionUrl(routeParams, 'customers')}
              />
            </ul>
          </div>
          {this.resourceSelector()}
        </div>
        {this.renderCalendarTitle(dateLabel)}
      </>
    );
  }

  renderCalendarTitle(subTitle) {
    const { calendarTitle } = this.props;

    return (
      <a href="#" onClick={this.showSelector} className="mobile-resource-header">
        <i className="far fa-bars" />
        {subTitle && (
          <p className="mobile-resource-title">
            {calendarTitle}
            {subTitle && <span className="mobile-resource-sub-title">{subTitle}</span>}
          </p>
        )}
      </a>
    );
  }

  resourceSelector() {
    return (
      <ResourceSelector
        onOptionClick={this.props.onClick}
        routeParams={this.props.routeParams}
        resourceTargetUrlResolver={this.resolveResourceTargetUrl}
        groupTargetUrlResolver={this.resolveGroupTargetUrl}
      />
    );
  }

  getRouteParams = () => {
    const { routeParams, viewDate, lastView } = this.props;
    return {
      ...routeParams,
      viewDate: viewDate || lastView?.viewDate || moment()
    };
  };

  resolveResourceTargetUrl = (resourceId, resourceEntityType) => {
    return getResourceUrl(resourceId, resourceEntityType, 'week', this.getRouteParams());
  };

  resolveGroupTargetUrl = (groupId) => {
    return getGroupUrl(groupId, this.getRouteParams());
  };
}

MobileNav.propTypes = {
  tabletMode: PropTypes.bool.isRequired,
  phoneMode: PropTypes.bool.isRequired,
  resourcesById: PropTypes.object.isRequired,
  orderedGroups: PropTypes.object.isRequired,
  routeParams: PropTypes.object.isRequired,
  changeGroup: PropTypes.func.isRequired,
  changeResource: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const { mainViewState, calendarViewState, orderedGroups, resourcesById } = state;
  const {
    viewDate, viewMode, entityType, entityId
  } = ownProps.routeParams;

  return {
    viewDate,
    viewMode,
    entityType,
    entityId,
    phoneMode: mainViewState.get('phoneMode'),
    tabletMode: mainViewState.get('tabletMode'),
    lastView: calendarViewState.get('lastView'),
    resourcesById,
    orderedGroups,
    calendarTitle: getCalendarTitle(state, ownProps),
    mainViewStyle: getMainViewStyle(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeGroup: (groupId) => {
      navigate(getGroupUrl(groupId, ownProps.routeParams));
    },
    changeResource: (resId, resourceEntityType) => {
      const { routeParams } = ownProps;
      navigate(getResourceUrl(resId, resourceEntityType, routeParams.viewMode, routeParams));
    },
    toggleScrollability: (scrollable) => {
      dispatch(toggleGridScrollability(scrollable));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileNav);
