import React from 'react';
import { useSelector } from 'react-redux';
import { bkfModals } from '@State/bkf/constants';
import CustomerModal from '@Components/customers/customer-modal';
import BookingEventsModal from '@Components/calendar/booking/booking-events-modal';
import BookingPaymentModal from '@Components/calendar/booking/booking-payment-modal';
import BookingPrintModal from '@Components/calendar/booking/booking-print-modal';
import BookingClassModal from '@Components/calendar/booking/booking-class-modal';
import BookingAddCustomerModal from '@Components/calendar/booking/booking-add-customer-modal';
import SendSmsModal from '@Components/customers/customer-detail/send-sms/send-sms-modal';
import NotificationModal from '@Components/dialogs/app-notification-modal';

const BookingModals = ({ routeParams }) => {
  const notification = useSelector(({ appState }) => appState.get('notification'));
  const customerModal = useSelector(({ cf }) => cf.get('formVisible'));
  const showModal = useSelector(({ bkf }) => bkf.get('showModal'));
  return (
    <>
      {customerModal && <CustomerModal routeParams={routeParams} />}
      {notification && <NotificationModal routeParams={routeParams} />}
      {showModal === bkfModals.print && <BookingPrintModal />}
      {showModal === bkfModals.events && <BookingEventsModal />}
      {showModal === bkfModals.payment && <BookingPaymentModal />}
      {showModal === bkfModals.class && <BookingClassModal routeParams={routeParams} />}
      {showModal === bkfModals.addCustomer && <BookingAddCustomerModal routeParams={routeParams} />}
      {showModal === bkfModals.sendSms && <SendSmsModal />}
    </>
  );
};

export default BookingModals;
