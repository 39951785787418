import React from 'react';
import { Field } from 'redux-form';
import { required, number, minValue } from '@Utils/validation';
import { Panel } from '../ui/styled/main';
import TextInput from '../inputs/text-input';

const ServiceFormTime = ({ isAddonService }) => (
  <>
    <label className="control-label mt2">Tidsåtgång</label>
    <Panel>
      <Field
        name="serviceDuration"
        component={TextInput}
        label="Längd"
        width={100}
        unitLabel="min"
        helpText="Ange hur lång tid som ska reserveras vid bokning av den här tjänsten."
        validate={isAddonService ? [number, minValue(0)] : [required, number, minValue(1)]}
      />
      <Field
        name="afterTime"
        component={TextInput}
        label="Paus efter"
        width={100}
        unitLabel="min"
        helpText="Reservera extra tid efter bokningen, tex för städning."
        validate={[number, minValue(0)]}
      />
    </Panel>
  </>
);

export default ServiceFormTime;
