import axios from 'axios';
import moment from 'moment';
import isRetryAllowed from 'is-retry-allowed';
import { NetworkError } from '@Utils/network-error';
import { versionMismatch, networkFailure } from '@State/app-actions';
import { changeAccountStatus } from '@State/account-actions';
import { getOrgWideCustomerDb } from '@State/selectors';
import { logoutOperator } from '@State/pos-actions';
import { getAccessToken, NO_AUTH_TOKEN } from '@Login/actions';
import { prefixAjaxUrl, prefixApiUrl, prefixWithOrgLoc } from './url-util';
import { apiUrl, clientBuildNumber } from './config';
import { getSessionOpToken } from './session';
import { isEmbeddedOrWrapped } from './embedded-util';
import { postWebkitMessage } from './wk-embed-bridges';
export const prefixUrl = (url) => prefixAjaxUrl(url);
export const prefixOrgUrl = (url) => prefixAjaxUrl(url, true);
export const prefixSearchUrl = (url, state) => {
    return getOrgWideCustomerDb(state) ? prefixOrgUrl(url) : prefixUrl(url);
};
export const prefixIcalUrl = (url) => prefixAjaxUrl(url, false, true);
export const prefixV2Url = (url) => prefixAjaxUrl(url, false, false, true);
export function prefixCustomerUrl(url, state) {
    const { locationOptions, customerById } = state;
    if (getOrgWideCustomerDb(state)) {
        const locationId = customerById.get('locationId');
        const location = locationOptions.find(l => l.locationId === locationId);
        if (location) {
            const { orgCtxName, locCtxName } = location;
            return prefixApiUrl(prefixWithOrgLoc(url, orgCtxName, locCtxName));
        }
    }
    return prefixUrl(url);
}
export function logError(error) {
    console.error('Exception in fetch chain', error.message, error);
    window.Sentry.captureException(error);
}
function networkErrorHandler(error, callback) {
    if (error) {
        logError(error);
    }
    if (callback) {
        callback();
    }
    if (error.clientDetails) {
        const { title, message } = error.clientDetails;
        return networkFailure(title, message);
    }
    return networkFailure();
}
export function handleInvalidToken(hasAccessToken) {
    return (dispatch, getState) => {
        if (isEmbeddedOrWrapped(getState())) {
            postWebkitMessage(hasAccessToken ? 'invalidToken' : 'noAccessToken', {
                apiUrl: apiUrl(),
                token: getAccessToken()
            });
        }
        else {
            dispatch({ type: NO_AUTH_TOKEN });
        }
    };
}
export function axiosErrorHandler(error, dispatch, callback = null) {
    if (error.message === 'Operation canceled by the user.') {
        return;
    }
    if (error.response && error.response.status === 401) {
        dispatch(handleInvalidToken(true));
    }
    else if (error.response && error.response.status === 498) {
        dispatch(logoutOperator());
    }
    else {
        dispatch(networkErrorHandler(error, callback));
    }
}
function checkVersionAxios(headers) {
    return (dispatch) => {
        const requiredVersion = headers['x-clientoclientversion'];
        const currentVersion = clientBuildNumber();
        if (currentVersion) {
            if (currentVersion !== 'LOCAL' && requiredVersion != currentVersion) {
                console.warn(`New client version available, refresh required. Current version: ${currentVersion}, new version: ${requiredVersion}`);
                dispatch(versionMismatch(currentVersion, requiredVersion));
            }
        }
    };
}
function checkAccountStatusAxios(headers) {
    return (dispatch) => {
        const features = headers['x-cliento-features'];
        const trialUntil = headers['x-cliento-trial-until'];
        const trialStatus = headers['x-cliento-trial-status']; // Tri/al, TrialExpired
        const accountStatus = headers['x-cliento-account-status']; // Active, ActivePaymentRequired, BlockedPaymentRequired, BlockedManual, Cancelled
        if (accountStatus) {
            const trialUntilMoment = trialUntil ? moment(trialUntil, 'YYYY-MM-DDTHH:mm:ssZ') : null;
            dispatch(changeAccountStatus(accountStatus, trialStatus, trialUntilMoment, features));
        }
    };
}
function handleResponseError(response) {
    return (dispatch) => {
        if (response.status === 401) {
            dispatch(handleInvalidToken(true));
        }
        else if (response.status === 498) {
            dispatch(logoutOperator());
        }
        else {
            throw new NetworkError(response);
        }
    };
}
export function checkStatusAxios(response) {
    return (dispatch) => {
        dispatch(checkAccountStatusAxios(response.headers));
        dispatch(checkVersionAxios(response.headers));
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        dispatch(handleResponseError(response));
        return Promise.reject();
    };
}
function getDefaultHeaders(accept = null, contentType = null) {
    const accessToken = localStorage.getItem('accessToken') || '';
    const operatorToken = getSessionOpToken() || '';
    const pusherSocketId = window.pusherSocketId || '';
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: accept || 'application/json',
        'Content-Type': contentType || 'application/json'
    };
    if (pusherSocketId) {
        headers['X-Client-Id'] = pusherSocketId;
    }
    if (operatorToken) {
        headers['X-ClientoOpToken'] = operatorToken;
    }
    return headers;
}
export function axiosDefault(props = {}) {
    return {
        ...props,
        withCredentials: true,
        headers: getDefaultHeaders()
    };
}
export function axiosFormData() {
    return {
        withCredentials: true,
        headers: getDefaultHeaders(null, 'multipart/form-data')
    };
}
function axiosRequest(requestMethod, options) {
    const { onSuccess, onError, throwOnError, errorDetails } = options || {};
    const config = axiosDefault(options?.config);
    return (dispatch) => {
        return requestMethod(config)
            .then(res => dispatch(checkStatusAxios(res)))
            .then(res => {
            if (onSuccess) {
                return onSuccess(res);
            }
        })
            .catch(error => {
            if (typeof errorDetails === 'function') {
                error.clientDetails = errorDetails(error);
            }
            if (throwOnError === true || (typeof throwOnError === 'function' && throwOnError(error))) {
                throw error;
            }
            axiosErrorHandler(error, dispatch, onError);
        });
    };
}
export function axiosGet(url, options = null) {
    const requestMethod = config => axios.get(url, config);
    return axiosRequest(requestMethod, options);
}
export function axiosPost(url, data, options = null) {
    const requestMethod = config => axios.post(url, data, config);
    return axiosRequest(requestMethod, options);
}
export function axiosPut(url, data, options = null) {
    const requestMethod = config => axios.put(url, data, config);
    return axiosRequest(requestMethod, options);
}
export function axiosPatch(url, data, options = null) {
    const requestMethod = config => axios.patch(url, data, config);
    return axiosRequest(requestMethod, options);
}
export function axiosDelete(url, options) {
    // Send null data to prevent axios from removing the content-type header
    // Some of our endpoints are expecting an application/json content-type
    const requestMethod = config => axios.delete(url, { ...config, data: null });
    return axiosRequest(requestMethod, options);
}
export function isNetworkError(error) {
    return !error.response
        && Boolean(error.code) // Prevents retrying cancelled requests
        && isRetryAllowed(error); // Prevents retrying unsafe errors
}
const SAFE_HTTP_METHODS = ['get', 'head', 'options'];
const IDEMPOTENT_HTTP_METHODS = SAFE_HTTP_METHODS.concat(['put', 'delete']);
export function isRetryableError(error) {
    return (!error.response || (error.response.status >= 501 && error.response.status <= 599));
}
export function isSafeRequestError(error) {
    if (!error.config) {
        // Cannot determine if the request can be retried
        return false;
    }
    return isRetryableError(error) && SAFE_HTTP_METHODS.indexOf(error.config.method) !== -1;
}
export function isIdempotentRequestError(error) {
    if (!error.config) {
        // Cannot determine if the request can be retried
        return false;
    }
    return isRetryableError(error) && IDEMPOTENT_HTTP_METHODS.indexOf(error.config.method) !== -1;
}
export function isNetworkOrIdempotentRequestError(error) {
    return isNetworkError(error) || isIdempotentRequestError(error);
}
export function isNetworkOrSafeRequestError(error) {
    return isNetworkError(error) || isSafeRequestError(error);
}
