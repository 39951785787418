import { Map } from 'immutable';
import { omit, isEmpty } from 'ramda';
import { createSelector } from 'reselect';
import {
  SET_BKF_BOOKING,
  RESET_BKF,
  SET_BKF_PROP,
  SET_BKF_CUSTOMER,
  SET_BKF_CUSTOMER_STATUS,
  SET_BKF_COMPANY,
  SET_BKF_VEHICLE,
  RES_SRV_LOADED,
  SET_BKF_SERVICE,
  ADD_BKF_SERVICE,
  REMOVE_BKF_SERVICE,
  UPDATE_BKF_SERVICE,
  ADD_BKF_RESOURCE,
  REMOVE_BKF_RESOURCE,
  RESET_BKF_NEW_RESOURCE,
  SET_BKF_RES_TIME,
  SET_BKF_ATTRIBUTES,
  TOGGLE_BKF_MODAL,
  CLEAR_RES_SRV,
  BOOKING_SALE_LOADED,
  BOOKING_REMINDERS_LOADED,
  BOOKING_CUSTOMER_ADDED,
  BOOKING_CUSTOMER_REMOVED
} from '@State/bkf/constants';

import { CANCEL_BOOKING, PRE_PAYMENT_ADDED } from '@State/booking-actions';
import { POS_INVOICE_STATUS_UPDATED } from '@State/invoice-actions';
import { CLEAR_LOCATION_STATE } from '@State/account-actions';
import { APP_NOTIFICATION } from '@State/app-actions';

import { formatPhoneNumber } from '@Utils/phone-util';
import { booking as bookingKeys } from '@Utils/preference-keys';
import { bookingTypes, getCompanyCustomer, getPrimaryCustomer, getVehicleCustomer } from '@Utils/booking-util';

export const getBkfServiceArray = createSelector(
  state => state.bkf.get('services'),
  (services) => services?.toArray()
);

function setBooking(state, action) {
  const { booking, colIdx, sourceResourceId } = action;

  return state.clear().withMutations((map) => {
    const fields = omit(['resources', 'services', 'customers'], booking);

    map.set('colIdx', colIdx);
    map.set('sourceResourceId', sourceResourceId);

    Object.keys(fields).forEach((key) => {
      map.set(key, booking[key]);
    });

    const vehicle = getVehicleCustomer(booking);
    if (vehicle) {
      map.set('vehicle', vehicle);
    } else {
      map.set('vehicle', null);
    }

    const company = getCompanyCustomer(booking);
    if (company) {
      map.set('company', company);
    } else {
      map.set('company', null);
    }

    const customer = getPrimaryCustomer(booking);
    if (customer) {
      map.set('customer', {
        ...customer,
        phoneNumber: formatPhoneNumber(customer.phoneNumber),
        otherPhoneNumber: formatPhoneNumber(customer.otherPhoneNumber)
      });
      map.set('attributes', { ...booking.attributes, ...customer.bookingAttributes });
    } else {
      map.set('customer', null);
    }

    if (booking.customFields && !isEmpty(booking.customFields)) {
      const customValues = {};
      booking.customFields.forEach(({ key, value }) => {
        customValues[key] = value;
      });
      map.set('customValues', customValues);
    }

    const {
      services, endTime, startTime, afterTime, resources, customers
    } = booking;

    const totalDuration = endTime.diff(startTime, 'minutes');
    const serviceDuration = totalDuration - (afterTime || 0);
    const hasServices = services && services.length > 0;
    const serviceDescriptions = hasServices ? services.map(s => s.name).join(', ') : '';

    map.set('service', {
      name: booking.description || serviceDescriptions || null,
      afterTime: booking.afterTime,
      price: booking.price,
      serviceDuration,
      totalDuration
    });

    if (hasServices) {
      for (const service of services) {
        map.setIn(['services', service.id], service);
      }
    }
    if (resources) {
      for (const resource of resources) {
        map.setIn(['resources', resource.id], resource);
      }
    }
    if (customers?.length > 0) {
      for (const customer of customers) {
        map.setIn(['customers', customer.customerId], customer);
      }
    }

    const { attributes } = booking;
    if (attributes && attributes[bookingKeys.recurringRule]) {
      map.set('recurring', true);
    }
  });
}

function setResourceAndTime(state, action) {
  return state.withMutations((map) => {
    const service = map.get('service');
    const totalDuration = action.endTime.diff(action.startTime, 'minutes');
    const serviceDuration = service && totalDuration - (service.afterTime || 0);

    map.set('startTime', action.startTime);
    map.set('endTime', action.endTime);
    map.set('resourceId', action.resId);
    map.set('service', { ...service, serviceDuration, totalDuration });
  });
}

function setAttributes(state, action) {
  return state.set('attributes', { ...state.get('attributes'), ...action.attributes });
}

function setInvoiceStatus(state, action) {
  if (state.get('sale')?.invoiceStatus?.invoiceId === action.invoice.id) {
    return state.set('sale', {
      ...state.get('sale'),
      invoiceStatus: {
        ...state.get('sale').invoiceStatus,
        ...action.invoice
      }
    });
  }
  return state;
}

function updateCustomFields(state) {
  const customValues = {};
  const customFields = state.get('customFields') || [];
  const services = state.get('services');
  const serviceFields = services && services.reduce((fields, srv) => {
    return srv.customFields ? [...fields, ...srv.customFields] : fields;
  }, []);

  // Remove empty fields not required by services
  const filteredFields = customFields.filter((field) => {
    const required = serviceFields && serviceFields.find(f => f.key === field.key);
    return required || field.value;
  });

  // Add new fields required by services
  if (serviceFields && serviceFields.length > 0) {
    serviceFields.forEach((field) => {
      if (!filteredFields.find(f => f.key === field.key)) {
        filteredFields.push(field);
      }
    });
  }

  filteredFields.forEach(({ key, value }) => {
    customValues[key] = value;
  });
  return state.set('customFields', filteredFields.length > 0 ? filteredFields : null)
    .set('customValues', customValues);
}

function updateAttributes(state) {
  const attributes = state.get('attributes');
  const services = state.get('services');
  const serviceAttributes = services && services.reduce((attr, srv) => {
    return { ...attr, ...srv.attributes };
  }, {});
  state.set('attributes', { ...attributes, ...serviceAttributes });
}

function setService(state, action) {
  return state.withMutations((map) => {
    const { service } = action;
    if (service) {
      map.set('service', service);

      if (service.id) {
        map.delete('services').setIn(['services', service.id], service);
      }
      if (service.bookingType === bookingTypes.ClassBooking) {
        map.set('maxSlots', service.maxSlots);
      }
    }
    if (!service || !service.name) {
      map.delete('services');
    }
    updateCustomFields(map);
    updateAttributes(map);
  });
}

function addService(state, action) {
  return state.withMutations((map) => {
    map.setIn(['services', action.service.id], action.service);
    updateCustomFields(map);
    updateAttributes(map);
  });
}

function removeService(state, action) {
  return state.withMutations((map) => {
    map.deleteIn(['services', action.service.id]);
    updateCustomFields(map);
    updateAttributes(map);
  });
}

function updateService(state, action) {
  return state.withMutations((map) => {
    map.setIn(['services', action.service.id], action.service);
  });
}

function addResource(state, action) {
  return state.withMutations((map) => {
    map.setIn(['resources', action.resource.id], action.resource);
  });
}

function removeResource(state, action) {
  return state.withMutations((map) => {
    map.deleteIn(['resources', action.resource.id]);
  });
}

function resetNewResource(state, action) {
  const { isNew, ...resource } = action.resource;
  return state.setIn(['resources', action.resource.id], resource);
}

function setCustomer(state, action) {
  const customer = action.customer ? {
    ...action.customer,
    phoneNumber: formatPhoneNumber(action.customer.phoneNumber),
    otherPhoneNumber: formatPhoneNumber(action.customer.otherPhoneNumber)
  } : null;

  return state.set('customer', customer);
}

function setCustomerStatus(state, action) {
  const customer = state.getIn(['customers', action.customerId]);
  if (!customer) {
    return state;
  }
  const newCustomer = { ...customer, status: action.status };
  return state.setIn(['customers', action.customerId], newCustomer);
}

function addCustomer(state, action) {
  if (state.get('id') !== action.bookingId) {
    return state;
  }
  const bookedSlots = state.get('bookedSlots') + 1;
  const customer = action.customer ? {
    ...action.customer,
    phoneNumber: formatPhoneNumber(action.customer.phoneNumber),
    otherPhoneNumber: formatPhoneNumber(action.customer.otherPhoneNumber)
  } : null;
  return state.setIn(['customers', action.customer.customerId], customer)
    .set('bookedSlots', bookedSlots);
}

function removeCustomer(state, action) {
  if (state.get('id') !== action.bookingId) {
    return state;
  }
  const bookedSlots = state.get('bookedSlots') - 1;
  return state.deleteIn(['customers', action.customerId])
    .set('bookedSlots', bookedSlots);
}

function cancelBooking(state, action) {
  if (state.get('id') !== action.id) {
    return state;
  }
  if (state.get('type') === bookingTypes.ClassBooking) {
    const { customerIds, changes } = action;
    const bookedSlots = state.get('bookedSlots') - customerIds.length;

    return state.withMutations((map) => {
      customerIds.forEach((customerId) => {
        map.mergeIn(['customers', customerId], changes);
      });
      map.set('bookedSlots', bookedSlots);
    });
  }
  return state;
}

function addExternalPayment(state, action) {
  return state.withMutations((map) => {
    const payments = state.get('payments') || [];
    map.set('payments', [...payments, action.payment]);

    if (action.sale) {
      const sales = state.get('sales') || [];
      map.set('sales', [...sales, action.sale]);
      map.set('sale', null);
    }
  });
}

export function bkf(state = Map(), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
    case RESET_BKF:
      return state.clear();
    case APP_NOTIFICATION:
      return action.data ? state.clear() : state;
    case SET_BKF_PROP:
      return state.set(action.key, action.value);
    case SET_BKF_ATTRIBUTES:
      return setAttributes(state, action);
    case SET_BKF_RES_TIME:
      return setResourceAndTime(state, action);
    case SET_BKF_BOOKING:
      return setBooking(state, action);
    case SET_BKF_SERVICE:
      return setService(state, action);
    case ADD_BKF_SERVICE:
      return addService(state, action);
    case REMOVE_BKF_SERVICE:
      return removeService(state, action);
    case UPDATE_BKF_SERVICE:
      return updateService(state, action);
    case ADD_BKF_RESOURCE:
      return addResource(state, action);
    case REMOVE_BKF_RESOURCE:
      return removeResource(state, action);
    case RESET_BKF_NEW_RESOURCE:
      return resetNewResource(state, action);
    case SET_BKF_VEHICLE:
      return state.set('vehicle', action.vehicle);
    case SET_BKF_COMPANY:
      return state.set('company', action.company);
    case SET_BKF_CUSTOMER:
      return setCustomer(state, action);
    case BOOKING_CUSTOMER_ADDED:
      return addCustomer(state, action);
    case BOOKING_CUSTOMER_REMOVED:
      return removeCustomer(state, action);
    case SET_BKF_CUSTOMER_STATUS:
      return setCustomerStatus(state, action);
    case TOGGLE_BKF_MODAL:
      return action.show
        ? state.set('showModal', action.modal).set('modalProps', action.props)
        : state.delete('showModal');
    case BOOKING_SALE_LOADED:
      return state.set('sale', action.sale);
    case BOOKING_REMINDERS_LOADED:
      return state.set('allReminders', action.reminders);
    case PRE_PAYMENT_ADDED:
      return addExternalPayment(state, action);
    case POS_INVOICE_STATUS_UPDATED:
      return setInvoiceStatus(state, action);
    case CANCEL_BOOKING:
      return cancelBooking(state, action);
    default:
      return state;
  }
}

export function resourceServices(state = Map(), action = null) {
  switch (action.type) {
    case CLEAR_RES_SRV:
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case RES_SRV_LOADED:
      return state.set(action.resId, action.services).set('timestamp', new Date());

    default:
      return state;
  }
}
