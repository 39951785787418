import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, fs } from '@Components/ui/styled/variables';
import { txt } from '@Utils/i18n-util';
import msg from './customers-list.msg';

const PaginationWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${colors.softGray60};
  padding: 10px;
`;

const PaginationLabel = styled.span`
  margin: 5px 10px;
  font-size: ${fs.s};
`;

const CustomerListFooter = ({ pageCount, handlePageClick, pageIndex, totalCount }) => (
  <PaginationWrap>
    <button
      className="btn btn-default"
      onClick={() => handlePageClick(pageIndex - 1)}
      disabled={pageIndex <= 0}
    >
      <i className="fa fa-chevron-left" />
    </button>
    <PaginationLabel>
      {txt(msg.lblCustomers, { count: totalCount })}
      <span className="ml1 mr1">&bull;</span>
      {txt(msg.lblPage, { count: pageIndex + 1, total: pageCount })}
    </PaginationLabel>
    <button
      className="btn btn-default"
      onClick={() => handlePageClick(pageIndex + 1)}
      disabled={pageIndex + 1 >= pageCount}
    >
      <i className="fa fa-chevron-right" />
    </button>
  </PaginationWrap>
);

CustomerListFooter.propTypes = {
  pageCount: PropTypes.number,
  currentPage: PropTypes.number,
  handlePageClick: PropTypes.func
};

export default CustomerListFooter;
