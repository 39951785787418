import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ModalDialog from '@Components/dialogs/modal-dialog';
import CurrencyUtil from '@Utils/currency-util';
import { getSmsEnabled } from '@State/selectors';
import { getPosOrgPrefs } from '@State/pos-selectors';
import { getPaymentMethodName } from '@Utils/pos-utils';
import { formatPhoneNumber, formatPhoneNumberE164 } from '@Utils/phone-util';
import { fetchBooking } from '@State/booking-actions';
import { colors } from '@Components/ui/styled/variables';
import RecipientAutosuggestion from '@Components/pos/payment/recipient-autosuggestion';
import { Flex, Text } from '@Components/ui/styled/main';
import Panel from '@Components/ui/styled/panel';
import { txt } from '@Utils/i18n-util';
import msg from './pos-payment.msg';

export const Icon = styled.i`
  font-size: 24px;
  margin-right: 10px;
  color: ${colors.statusGreen};
`;

const HeaderContainer = styled.div`
  margin: 10px auto 26px;
  display: flex;
  align-items: center;
`;

class ReceiptModal extends Component {
  constructor(props) {
    super(props);

    const { email, phoneNumber } = props.customer || {};

    this.state = {
      deliveryMethod: null,
      emailRecipient: email || '',
      smsRecipient: formatPhoneNumber(phoneNumber || ''),
      highlightedSuggestion: '',
      progress: false
    };

    this.recipientRef = React.createRef();
  }

  componentDidMount() {
    const { email, phoneNumber } = this.props.customer || {};
    if (this.props.bookingId && !email && !phoneNumber) {
      this.props.fetchBooking(this.props.bookingId)
        .then((booking) => this.setState({
          emailRecipient: booking.customerEmail || '',
          smsRecipient: formatPhoneNumber(booking.customerPhoneNumber || '')
        }));
    }

    const { alwaysPrintReceipt, printerProgress, printerError } = this.props;
    if (alwaysPrintReceipt && !printerError && printerProgress) {
      this.onDelayedClose();
    }
  }

  componentDidUpdate(prevProps) {
    const { alwaysPrintReceipt, printerProgress, printerError } = this.props;
    const progressStarted = !prevProps.printerProgress && printerProgress;
    const progressCompleted = prevProps.printerProgress && !printerProgress;

    if (alwaysPrintReceipt && !printerError && progressStarted) {
      this.onDelayedClose();
    }
    if (alwaysPrintReceipt && !printerError && progressCompleted) {
      this.onClose();
    }
  }

  onDelayedClose = () => {
    this.timer = setTimeout(() => this.onClose(), 3000);
  };

  toggleEmail = () => {
    const newDeliveryMethod = this.state.deliveryMethod !== 'Email' ? 'Email' : null;
    this.setState({ deliveryMethod: newDeliveryMethod }, () => {
      if (newDeliveryMethod && this.recipientRef.current && !this.state.emailRecipient) {
        this.recipientRef.current.focus();
      }
    });
  };

  toggleSms = () => {
    const newDeliveryMethod = this.state.deliveryMethod !== 'SMS' ? 'SMS' : null;
    this.setState({ deliveryMethod: newDeliveryMethod }, () => {
      if (newDeliveryMethod && this.recipientRef.current && !this.state.smsRecipient) {
        this.recipientRef.current.focus();
      }
    });
  };

  onSubmit = () => {
    const { deliveryMethod, emailRecipient, smsRecipient } = this.state;
    const receiptData = {
      receiptDeliveryMethod: deliveryMethod || 'Print',
      emailReceiptTo: deliveryMethod === 'Email'
        ? emailRecipient
        : null,
      smsReceiptTo: deliveryMethod === 'SMS'
        ? formatPhoneNumberE164(smsRecipient)
        : null
    };
    this.setState({ progress: true });
    return this.props.onSubmit(receiptData)
      .catch(() => this.setState({ progress: false }));
  };

  onThrow = () => {
    const receiptData = {
      receiptDeliveryMethod: 'Bin'
    };
    this.setState({ progress: true });
    return this.props.onSubmit(receiptData)
      .catch(() => this.setState({ progress: false }));
  };

  onClose = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    return this.props.onSubmit();
  };

  onRecipientChange = (ev) => {
    const { deliveryMethod } = this.state;
    if (deliveryMethod === 'Email') {
      this.setState({ emailRecipient: ev.target.value });
    } else if (deliveryMethod === 'SMS') {
      this.setState({ smsRecipient: ev.target.value });
    }
  };

  onSuggestionSelected = (value) => {
    const { deliveryMethod } = this.state;
    if (deliveryMethod === 'Email') {
      this.setState({ emailRecipient: value });
    } else if (deliveryMethod === 'SMS') {
      this.setState({ smsRecipient: value });
    }
  };

  getButtons = () => {
    const {
      printerProgress, defaultPrinterId, printerError,
      alwaysPrintReceipt, hidePrint, smsEnabled
    } = this.props;
    const { deliveryMethod, emailRecipient, smsRecipient, progress } = this.state;
    const printAvailable = !printerError && defaultPrinterId;

    if (alwaysPrintReceipt && printAvailable) {
      return [{
        name: txt(msg.lblClose),
        gray: true,
        onClick: this.onClose
      }];
    }

    if (deliveryMethod === 'Email') {
      return [{
        disabled: emailRecipient === '',
        name: txt(msg.lblSend),
        primary: true,
        loading: progress,
        onClick: this.onSubmit
      }, {
        disabled: progress,
        name: txt(msg.lblCancel),
        gray: true,
        onClick: this.toggleEmail
      }];
    }

    if (deliveryMethod === 'SMS') {
      return [{
        disabled: smsRecipient === '',
        name: txt(msg.lblSend),
        primary: true,
        loading: progress,
        onClick: this.onSubmit
      }, {
        disabled: progress,
        name: txt(msg.lblCancel),
        gray: true,
        onClick: this.toggleSms
      }];
    }

    return [{
      disabled: progress,
      name: txt(msg.lblEmail),
      primary: true,
      onClick: this.toggleEmail
    }, {
      disabled: progress || !smsEnabled,
      name: smsEnabled ? txt(msg.lblSms) : txt(msg.lblSmsNotAvailable),
      gray: true,
      onClick: this.toggleSms
    }, !hidePrint && {
      disabled: !printAvailable,
      name: printAvailable ? txt(msg.lblPrint) : txt(msg.lblPrintNotAvailable),
      gray: true,
      loading: printerProgress || progress,
      onClick: this.onSubmit
    }, {
      disabled: progress,
      name: txt(msg.lblThrow),
      danger: true,
      marginTop: true,
      onClick: this.onThrow
    }];
  };

  onEnter = () => {
    const { deliveryMethod, progress } = this.state;

    if (deliveryMethod && !progress) {
      return this.onSubmit();
    }
    if (!deliveryMethod && !progress) {
      return this.toggleEmail();
    }
    return null;
  };

  getRecipient = () => {
    const { deliveryMethod, emailRecipient, smsRecipient } = this.state;
    switch (deliveryMethod) {
      case 'Email':
        return emailRecipient;
      case 'SMS':
        return smsRecipient;
      default:
        return '';
    }
  };

  render() {
    const {
      totalAmount, changeAmount, tipAmount, transactions, isRefund, posOrgPrefs,
      alwaysPrintReceipt, printerError, defaultPrinterId
    } = this.props;
    const { deliveryMethod } = this.state;
    const subTitle = isRefund ? txt(msg.lblRefundCompleted) : txt(msg.lblPaymentCompleted);
    const recipient = this.getRecipient();
    const printAvailable = !printerError && defaultPrinterId;
    const isAutoPrinting = alwaysPrintReceipt && printAvailable;

    return (
      <ModalDialog
        className="dialog"
        onEnter={this.onEnter}
        buttons={this.getButtons()}
      >
        {!deliveryMethod && (
          <>
            <HeaderContainer>
              <Icon className="fa fa-check-circle" />
              <Text fontWeight={600} fs={17}>{subTitle}</Text>
            </HeaderContainer>
            <Panel>
              <Flex>
                <Flex><Text fs={20}>{isRefund ? txt(msg.lblRefunded) : txt(msg.lblPaid)}</Text></Flex>
                <Flex right><Text fs={20}>{CurrencyUtil.accountCurrency(Math.abs(totalAmount), 2)}</Text></Flex>
              </Flex>
              {transactions && transactions.map((transaction) => {
                const paymentMethod = transaction.get('paymentMethodName') || transaction.get('paymentMethod');
                return (
                  <Flex key={transaction.get('paymentId')}>
                    <Flex><Text>{getPaymentMethodName(paymentMethod, posOrgPrefs)}</Text></Flex>
                    <Flex right><Text>{CurrencyUtil.accountCurrency(transaction.get('amount'), 2)}</Text></Flex>
                  </Flex>
                );
              })}
              {tipAmount > 0 && (
                <Flex>
                  <Flex><Text primary>⭐️ {txt(msg.lblTip)}</Text></Flex>
                  <Flex right><Text primary>{CurrencyUtil.accountCurrency(tipAmount, 2)}</Text></Flex>
                </Flex>
              )}
              {changeAmount > 0 && (
                <Flex>
                  <Flex><Text primary>{txt(msg.lblChange)}</Text></Flex>
                  <Flex right><Text primary>{CurrencyUtil.accountCurrency(changeAmount, 2)}</Text></Flex>
                </Flex>
              )}
            </Panel>
            <Text center bold marginTop="8px">
              {isAutoPrinting ? txt(msg.lblPrinting) : txt(msg.lblReceiptHeading)}
            </Text>
          </>
        )}
        {deliveryMethod && (
          <RecipientAutosuggestion
            value={recipient}
            inputRef={this.recipientRef}
            deliveryMethod={deliveryMethod}
            onChange={this.onRecipientChange}
            onSelect={this.onSuggestionSelected}
          />
        )}
      </ModalDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  smsEnabled: getSmsEnabled(state),
  posOrgPrefs: getPosOrgPrefs(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchBooking: (id) => dispatch(fetchBooking(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptModal);
